<div class="login-form-container">
  <div class="login-form max-width">
    <img src="./assets/icons/VIP-Vert-Negative.svg" alt="VIP Icon" class="vip-icon">

    <h5>Update Email Address</h5>

    <form [formGroup]="confirmationForm" (keydown)="submitOnEnter($event)">
      <mat-form-field [ngClass]="{'valid': confirmationForm.controls.confirmationCode.valid}" appearance="outline">
        <mat-label>Confirmation Code</mat-label>
        <input formControlName="confirmationCode" matInput>
        <mat-error *ngIf="hasError('confirmationCode')">{{ getErrorMessage('confirmationCode', ['required']) }}</mat-error>
      </mat-form-field>
    </form>

    <div class="spinner" *ngIf="isLoading">
      <mat-spinner diameter="48"></mat-spinner>
    </div>
    <button *ngIf="!isLoading" [ngClass]="{'army-default-inactive': !isValid()}" class="army-button army-default" (click)="submit()">Submit</button>
    <div class="login-footer">
      <a href="https://www.goarmy.com/?iom=AFOD-2675107_VIP_Website" target="_blank" class="goarmy-link"><img src="./assets/icons/army_logo1_rev_rgb.svg" alt="US Army Logo" class="army-logo"></a>
    </div>
  </div>
</div>


