import { Component, OnInit, Input } from '@angular/core';
import { LeaderboardOverview } from '../../models/dashboard.model';

@Component({
  selector: 'app-leaderboards',
  templateUrl: './leaderboards.component.html',
  styleUrls: ['./leaderboards.component.scss']
})
export class LeaderboardsComponent implements OnInit {
  @Input()
  leaderboards: any;

  @Input()
  assets: any;

  @Input()
  username: any;

  leaderboardArray = [];
  notPlayed: boolean;

  ngOnInit(): void {
    if (this.assets) {
      this.assets.forEach((asset) => {
        if (asset.leaderboard) {
          if (asset.leaderboard.identifier === 'CROWS') {
            if (this.leaderboards.crowsLeaderBoard) {
              const playerPosition =
                this.leaderboards.crowsLeaderBoard.leaderboards.players.find(
                  (player) => {
                    if (player.playerId === this.username) {
                      return player.rank;
                    }
                  }
                );
              if (!playerPosition) {
                this.notPlayed = true;
              }
              this.leaderboardArray.push({
                ...asset,
                ...this.leaderboards.crowsLeaderBoard,
                playerPosition
              });
            }
          }
        }
      });
    }
  }
}
