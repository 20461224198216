import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { AuthService } from '../../services/auth.service';
import { UsernameValidator } from '../../validators/username-validator';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
  @Input()
  buttonLoading: boolean;

  @Input()
  userInfo: any;

  @Output()
  passwordChange = new EventEmitter<any>();

  passwordForm: FormGroup;

  submitted: boolean;

  validationMessages = {
    emailAddress: {
      required: 'Email is required.',
      email: 'Email address is invalid.'
    },
    oldPassword: {
      required: 'Password is required.'
    },
    newPassword: {
      required: 'Password is required.',
      pattern:
        'Your password must be at least 8 characters. It must include upper and lowercase letters and at least 1 number.'
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.email]],
      oldPassword: ['', [Validators.required]],
      newPassword: [
        '',
        [
          Validators.required,
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$'),
          Validators.pattern(
            '^[A-Za-z0-9!@#$%^&*()\\-`.+,/"]+(?: +[A-Za-z0-9!@#$%^&*()\\-`.+,/"]+)*$'
          )
        ]
      ]
    });
    if (this.userInfo) {
      this.passwordForm.patchValue({
        emailAddress: this.userInfo.emailAddress
      });
    }
  }

  hasError(key: string): boolean {
    const control = this.passwordForm.get(key);

    if (!control) {
      return false;
    }

    if (
      (!control.valid && control.touched) ||
      (control.untouched && this.submitted)
    ) {
      return control.errors ? true : false;
    }
  }

  getErrorMessage(key: string, types: Array<string>): any {
    const field = key;
    const control = this.passwordForm.get(field);

    if (this.hasError(key)) {
      for (const errorType in types) {
        if (control.errors[types[errorType]]) {
          return this.validationMessages[key][types[errorType]];
        }
      }
    }
  }

  back(): void {
    this.location.back();
  }

  changePassword(): void {
    this.submitted = true;
    this.passwordChange.emit(this.passwordForm.value);
    this.passwordForm.controls.newPassword.reset();
    this.passwordForm.controls.oldPassword.reset();
  }
}
