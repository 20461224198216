import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select, Action } from '@ngrx/store';
import * as fromStore from '../../store';
import { AppState } from '../../store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-change-email-container',
  templateUrl: './change-email-container.component.html',
  styleUrls: ['./change-email-container.component.scss']
})
export class ChangeEmailContainerComponent implements OnInit, OnDestroy {
  url: string;

  isLoggedIn$: Observable<boolean>;
  isUserLoading$: Observable<boolean>;

  isLoggedInSubscription: Subscription;
  isUserLoadingSubscription: Subscription;

  isLoggedIn: boolean;
  isUserLoading: boolean;

  changeEmailLoading$: Observable<boolean>;
  cancelChangeEmailSuccess$: Observable<boolean>;
  changeEmailError$: Observable<string>;

  constructor(
    private route: Router,
    private store: Store<AppState>
  ) {
    this.url = this.route.url;
    this.isLoggedIn$ = this.store.pipe(select(fromStore.isLoggedIn));
    this.isUserLoading$ = this.store.pipe(select(fromStore.isUserLoading));
    this.changeEmailLoading$ = this.store.pipe(
      select(fromStore.changeEmailLoading)
    );
    this.cancelChangeEmailSuccess$ = this.store.pipe(
      select(fromStore.cancelChangeEmailSuccess)
    );
    this.changeEmailError$ = this.store.pipe(
      select(fromStore.getChangeEmailError)
    );
  }

  ngOnInit(): void {
    if (this.isLoggedIn$) {
      this.isLoggedInSubscription = this.isLoggedIn$.subscribe(
        (loggedIn) => (this.isLoggedIn = loggedIn)
      );
    }
    if (this.isUserLoading$) {
      this.isUserLoadingSubscription = this.isUserLoading$.subscribe(
        (userLoading) => (this.isUserLoading = userLoading)
      );
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(fromStore.CancelChangeEmailErrorsReset());
    if (this.isLoggedIn) {
      this.store.dispatch(fromStore.GetUserDetails());
      this.store.dispatch(fromStore.GetCrowsLeaderboard());
    }
  }

  confirmChangeEmail(code) {
    this.store.dispatch(fromStore.ConfirmChangeEmail(code.confirmationCode));
  }

  goHome(isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.store.dispatch(fromStore.GetUserDetails());
      this.store.dispatch(fromStore.GetCrowsLeaderboard());
    }
    this.route.navigateByUrl('/');
  }

  cancelChangeEmail(accountId) {
    this.store.dispatch(fromStore.CancelChangeEmail(accountId));
  }
}
