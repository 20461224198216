import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromStore from '../../store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-delayed-sign-up-container',
  templateUrl: './delayed-sign-up-container.component.html',
  styleUrls: ['./delayed-sign-up-container.component.scss']
})
export class DelayedSignUpContainerComponent implements OnInit, OnDestroy {
  url: string;
  loading: boolean;
  currentAsset: string;
  appId: string;
  assets$: Observable<any>;
  assetSub: Subscription;
  routeSub: Subscription;

  constructor(
    private router: Router,
    private store: Store<fromStore.AppState>,
    private activatedRoute: ActivatedRoute
  ) {
    this.routeSub = this.activatedRoute.params.subscribe((params) => {
      const appId = 'appId';
      this.appId = params[appId];
      if (!appId) {
        this.appId = 'AS-88ca66c0-2e30-446c-aa95-0ededc9f2a09';
      }
    });
    this.assets$ = this.store.pipe(select(fromStore.getAssetState));
  }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.assets$) {
      this.assetSub = this.assets$.subscribe((assets) => {
        if (assets ? (assets.vipAssets ? true : false) : false) {
          this.currentAsset = assets.vipAssets.find((asset) => {
            return asset.identifier === this.appId;
          });
          if (!this.currentAsset) {
            this.currentAsset = assets.vipAssets[0];
          }
        }
      });
    }
  }

  signup(form): void {
    this.store.dispatch(fromStore.DelayedSignup(form));
  }

  ngOnDestroy(): void {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if (this.assetSub) {
      this.assetSub.unsubscribe();
    }
  }
}
