<a [routerLink]="['/settings']" routerLinkActive="active">Account Settings</a>

<div class="personal-info-form max-width">
  <h2>Personal Info</h2>

  <form method="post">
    <label for="fname">First Name</label>
    <input type="text">

    <label for="lname">Last Name</label>
    <input type="text">

    <label for="email">Email</label>
    <input type="email">

    <label for="dob">Date of Birth</label>
    <input type="date">

    <label for="zipcode">Zip Code</label>
    <input type="number">

    <button>Save Changes</button>
  </form>

  <div class="info-links">
    <a href="/">Delete Account</a>
    <a href="/">Export My Data</a>
  </div>

</div>

