<div *ngIf="crowsLeaderboard" class="dashboard-card"
[ngStyle]="{ 'background': 'url(' + leaderboardAsset.backgroundImageURL + ')' }">
  <div class="leaderboards">
    <div class="leaderboard-header">
      <div class="header-text">
        <p class="caption">Leaderboard</p>
        <h5>CROWS Experience</h5>
      </div>
      <img [src]="leaderboardAsset.iconImageURL" alt="CROWS Gold Logo" class="crows-logo">
    </div>

    <div class="leaderboard-footer">
      <div class="position">
        <p class="caption">{{ notPlayed ? 'Participate to see your name on the leaderboard' : 'Your Position' }}</p>
        <p class="large-number">{{ currentRank }}</p>
      </div>
      <div class="total">
        <p *ngIf="!notPlayed" class="caption">Out of</p>
        <p class="caption">{{ notPlayed ? '' : crowsLeaderboard?.numberOfEntries | number }}</p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!crowsLeaderboard" class="dashboard-card empty"></div>
