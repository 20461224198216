import { Component, OnInit, Input } from '@angular/core';
import { ActivityStream } from '../../models/dashboard.model';
import { Assets } from '../../models/assets.model';

@Component({
  selector: 'app-activity-card',
  templateUrl: './activity-card.component.html',
  styleUrls: ['./activity-card.component.scss']
})
export class ActivityCardComponent {
  @Input()
  activityInfo: any;

  @Input()
  assets: any;

  getAsset() {
    if (this.activityInfo && this.assets) {
      let currentAsset;
      this.assets.forEach((asset) => {
        if (this.activityInfo.asset.includes(asset.identifier)) {
          currentAsset = asset;
        }
      });
      return currentAsset;
    }
  }

  getBackground(): any {
    const asset = this.getAsset();
    if (asset.backgroundImageURL) {
      return 'url(' + asset.backgroundImageURL + ')';
    } else {
      return '$wht-blk-charcoal-slate-gradient';
    }
  }

  getIcon(): string {
    const asset = this.getAsset();
    return asset.iconImageURL;
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const duration = new Date().getTime() - date.getTime();
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(duration / (1000 * 60 * 60 * 24) / 7);

    if (weeks > 1) {
      return `${weeks} Weeks Ago`;
    } else if (weeks === 1) {
      return `${weeks} Week Ago`;
    } else if (days > 1) {
      return `${days} Days Ago`;
    } else if (days === 1) {
      return `${days} Day Ago`;
    } else if (hours > 1) {
      return `${hours} Hours Ago`;
    } else if (hours === 1) {
      return `${hours} Hour Ago`;
    } else {
      return 'Just Now';
    }
  }
}
