import {
  Component,
  OnInit,
  HostListener,
  ChangeDetectorRef
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromRoot from './store';
import {
  Router,
  NavigationEnd,
  NavigationStart,
  RouterOutlet
} from '@angular/router';
import { slideInAnimation } from './route-animation';
import { ScrollService } from './services/scroll.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit {
  title = 'VIP';
  isQrCode = false;
  firstShow = false;
  qrToggled = false;
  arrowAnimate = false;
  loggedIn$: Observable<boolean>;
  firstLogin$: Observable<boolean>;
  userName$: Observable<string>;
  url: string;
  userDetails: any;
  userDetailsLoading: boolean;
  routesWithoutQrCode = [
    '/',
    '/welcome',
    '/login',
    '/signup',
    '/signup-welcome',
    '/signup-confirm',
    '/settings',
    '/forgot-password',
    '/update-password',
    '/update-info',
    '/reset-password',
    '/404',
    // TODO: Determine if QR header is needed when delayed sign-up is developed
    '/join',
    '/account/changeEmail/confirm'
  ];

  pageLocation: number;

  forwardAnimation: string;
  backwardAnimation: string;
  isLandscape = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isLandscape = window.innerHeight < 560;
  }

  constructor(
    private store: Store<fromRoot.AppState>,
    router: Router,
    private cd: ChangeDetectorRef,
    private scrollService: ScrollService
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.url = event.urlAfterRedirects;
        this.qrToggled = false;
        if (this.url.includes('changeEmail/cancel')) {
          this.routesWithoutQrCode.push(this.url);
          this.cd.detectChanges();
        }
        if (this.checkIsSafari()) {
          window.scrollTo(0, 0);
        }
        (window as any).dataLayer.push({
          event: 'PageView'
        });
      });
    router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.pageLocation = this.getPagePosition();
      });
  }

  ngOnInit(): void {
    this.store.dispatch(fromRoot.GetAssets());
    this.isLandscape = window.innerHeight < 560;
    this.loggedIn$ = this.store.select(fromRoot.isLoggedIn);
    this.firstLogin$ = this.store.select(fromRoot.firstLogin);
    this.store.pipe(select(fromRoot.getUserDetailsState)).subscribe((data) => {
      this.userDetails = data;
      this.userDetailsLoading = data.userDetailsLoading;
      if (!this.userDetailsLoading && this.firstShow) {
        this.firstLogin();
      }
    });
    this.firstLogin$.subscribe((bool) => {
      this.firstShow = bool;
      if (this.firstShow) {
        this.store.dispatch(fromRoot.GetUserDetails());
        this.store.dispatch(fromRoot.GetCrowsLeaderboard());
      }
    });
  }

  checkIsSafari(): boolean {
    const isPhone = navigator.userAgent.toLowerCase().includes('iphone');
    const isChrome = navigator.userAgent.toLowerCase().includes('chrome');
    const isSafari = navigator.userAgent.toLowerCase().includes('safari');
    if (isChrome || isPhone) {
      return false;
    } else {
      if (isSafari) {
        return this.getSafariVersion() <= 13;
      }
    }
  }

  getSafariVersion(): number {
    let safariVersion;
    navigator.userAgent.split(' ').forEach((i) => {
      if (i.toLowerCase().includes('version')) {
        safariVersion = Number(i.split('/')[1].split('.')[0]);
      }
    });
    return safariVersion;
  }

  toggleQrCode(): void {
    if (this.isQrCode && !this.firstShow) {
      this.qrToggled = true;
    } else if (this.isQrCode) {
      this.qrToggled = false;
    }
    this.isQrCode = !this.isQrCode;
  }

  firstLogin(): void {
    setTimeout(() => {
      this.store.dispatch(fromRoot.FirstLogin());
      this.firstShow = false;
      this.arrowAnimate = true;
      setTimeout(() => (this.arrowAnimate = false), 1000);
    }, 2980);
  }

  animEnd() {
    this.scrollService.scrollToTop();
  }

  getPagePosition() {
    return window.pageYOffset * -1;
  }

  getAnimationData(outlet: RouterOutlet) {
    return {
      value:
        outlet &&
        outlet.activatedRouteData &&
        outlet.activatedRouteData.animation,
      params: { location: this.pageLocation }
    };
  }

  qrCodeOnPage(): boolean {
    if (this.routesWithoutQrCode.includes(this.url)) {
      return false;
    }
    return true;
  }

  headerOnPage(): boolean {
    if (this.routesWithoutQrCode.includes(this.url)) {
      return false;
    }
    return true;
  }
}
