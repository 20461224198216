import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromUserDetails from '../reducers/userDetails.reducer';

export const getUserDetailsData = createSelector(
  fromFeature.getUserDetailsDataState,
  (state: fromFeature.AppState) => (state ? state.userDetailsData : {})
);

export const userDetailsAreLoading = createSelector(
  fromFeature.getUserDetailsState,
  fromUserDetails.userDetailsAreLoading
);

export const getUserInfo = createSelector(
  fromFeature.getUserDetailsState,
  fromUserDetails.getUserInfo
);

export const getNotifications = createSelector(
  fromFeature.getUserDetailsState,
  fromUserDetails.getNotifications
);
export const getActivities = createSelector(
  fromFeature.getUserDetailsState,
  fromUserDetails.getActivities
);
export const getInteractions = createSelector(
  fromFeature.getUserDetailsState,
  fromUserDetails.getInteractions
);
