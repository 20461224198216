import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss']
})
export class SignUpFormComponent implements OnInit {
  @ViewChild('email') email: ElementRef;

  @Input()
  buttonLoading: boolean;

  @Output()
  signupData = new EventEmitter<any>();

  @Output()
  verifyEmailEmitter = new EventEmitter<any>();

  signupFormGroup: FormGroup;

  currentIndex = 1;

  submitted = false;

  checkboxChecked = false;

  currentYear = formatDate(new Date(), 'yyyy', 'en');
  startDate = new Date(Number(this.currentYear) - 5, 0, 1);

  constructor(
    private readonly formBuilder: FormBuilder,
    private route: Router
  ) {}

  validationMessages = {
    email: {
      required: 'Email is required.',
      email: 'Email address is invalid.'
    },
    password: {
      required: 'Password is required.',
      pattern:
        'Your password must be at least 8 characters. It must include upper and lowercase letters and at least 1 number.'
    }
  };

  ngOnInit(): void {
    this.signupFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$'),
          Validators.pattern(
            '^[A-Za-z0-9!@#$%^&*()\\-`.+,/"]+(?: +[A-Za-z0-9!@#$%^&*()\\-`.+,/"]+)*$'
          )
        ]
      ]
    });
  }

  toggleChecked() {
    this.checkboxChecked = !this.checkboxChecked;
  }

  hasError(key: string, group?: string): boolean {
    const field = group ? `${group}.${key}` : key;
    const control = this.signupFormGroup.get(field);

    if (!control) {
      return false;
    }

    if (
      (!control.valid && control.touched) ||
      (control.untouched && this.submitted)
    ) {
      return control.errors ? true : false;
    }
  }

  getErrorMessage(key: string, types: Array<string>, group?: string): any {
    const field = group ? `${group}.${key}` : key;
    const control = this.signupFormGroup.get(field);

    if (this.hasError(key, group)) {
      for (const errorType of types) {
        if (control.errors[errorType]) {
          return this.validationMessages[key][errorType];
        }
      }
    }
  }

  verifyEmail(): void {
    if (this.signupFormGroup.valid) {
      this.verifyEmailEmitter.emit(this.signupFormGroup.value);
    }
  }

  formatDateOfBirth(dateOfBirth): string {
    const date = formatDate(dateOfBirth, 'yyyy-MM-dd', 'en-US');
    return date;
  }

  submitOnEnter(event: { keyCode: number }): void {
    if (event.keyCode === 13) {
      this.verifyEmail();
    }
  }
}
