<div class="achievement-detail-container">
  <div class="achievement">
    <div class="achievement-card" [ngStyle]="{ 'background': 'url(' + currentAchievement?.backgroundImageURL + ')' }">
        <div class="icon">
          <img [src]="currentAchievement?.iconImageURL" alt="Logo" class="logo">
        </div>

      <div class="achievement-card-footer">
          <img *ngIf="(completedObjectives?.length >= currentAchievement?.objectives?.length)" class="checkbox" src="./assets/icons/completed-check.svg" alt="check">
          <img *ngIf="(completedObjectives?.length < currentAchievement?.objectives?.length)" class="checkbox" src="./assets/icons/incompleted-box.svg" alt="check">
          <mat-progress-bar  mode="determinate" [value]="getProgressPercent()"></mat-progress-bar>
      </div>
    </div>

    <div class="title">
      <h2>{{ currentAchievement?.title }}</h2>
      <button class="mobile-secondary small">{{ completedObjectives?.length  }} of {{ currentAchievement?.objectives?.length }} Objectives Earned</button>
      <div class="info">
        <img src="./assets/icons/info.svg" alt="info" class="info-icon">
        <p class="smart-banner-subtitle">Some objectives require U.S Army Career Navigator to complete. Get Career Navigator for free! </p>
      </div>
    </div>

    <div class="full-row" *ngFor="let objective of currentAchievement?.objectives">
      <hr>
      <div class="achievement-row" [routerLink]="['/objective-detail/' + objective.identifier]">
        <img *ngIf="completedObjectives.includes(objective.identifier)" class="checkbox" src="./assets/icons/completed-check-green.svg" alt="check">
        <img *ngIf="!completedObjectives.includes(objective.identifier)" class="checkbox" src="./assets/icons/incompleted-box.svg" alt="check">
        <img [src]="objective.iconImageURL" alt="Objective Icon" class="objective-icon">
        <p [className]="completedObjectives.includes(objective.identifier) ? 'caption complete' : ' caption incomplete'">
          <span [className]="objective.objectiveType.includes('scannable') ? 'required' : 'incomplete'">{{ objective.name }}</span></p>
        <img src="./assets/icons/chevron_right.svg" alt="Right Arrow Icon" class="material-icons right">
      </div>
    </div>
    <hr>
  </div>
</div>
