<p class="dashboard-card-subtitle">Account Settings</p>
<h2>Change Password</h2>
<form [formGroup]="passwordForm">
  <mat-form-field [ngClass]="{'valid': passwordForm.controls.emailAddress.valid && passwordForm.value.emailAddress}" appearance="outline">
    <mat-label>Email Address</mat-label>
    <input matInput formControlName="emailAddress">
    <mat-error *ngIf="hasError('emailAddress')">{{ getErrorMessage('emailAddress', ['required', 'email']) }}</mat-error>
  </mat-form-field>

  <mat-form-field [ngClass]="{'valid': passwordForm.controls.oldPassword.valid && passwordForm.value.oldPassword}" appearance="outline">
    <mat-label>Old Password</mat-label>
    <input type="password" matInput formControlName="oldPassword">
    <mat-error *ngIf="hasError('oldPassword')">{{ getErrorMessage('oldPassword', ['required']) }}</mat-error>
  </mat-form-field>

  <mat-form-field [ngClass]="{'valid': passwordForm.controls.newPassword.valid && passwordForm.value.newPassword}" appearance="outline">
    <mat-label>New Password</mat-label>
    <input autocomplete="off" type="password" matInput formControlName="newPassword">
    <mat-hint class="body-tiny">Your password must be at least 8 characters. It must include upper and lowercase letters and at least 1 number.</mat-hint>
    <mat-error *ngIf="hasError('newPassword')">{{ getErrorMessage('newPassword', ['required', 'pattern']) }}</mat-error>
  </mat-form-field>

  <div>
    <div class="spinner" *ngIf="buttonLoading">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
    <button *ngIf="!buttonLoading" class="army-button army-default" [ngClass]="{'army-default-inactive': !passwordForm.valid}" [disabled]="!passwordForm.valid" (click)="changePassword()">Change Password</button>
  </div>
</form>

<div class="footer">
  <button [routerLink]="['/forgot-password']" class="army-button army-text">Forgot My Password</button>
</div>
