<div class="get" *ngIf='url !== "/login"'>
  <div class="cta-top-bar">
    <div class="logo">
      <a href="https://www.goarmy.com/?iom=AFOD-2675107_VIP_Website" target="_blank" class="goarmy-link"><img src="./assets/icons/army_logo2_blk_rgb.svg" alt="US Army Logo" class="army-logo"></a>
    </div>
    <div class="top-bar-text">
      <p class="smart-banner-title">U.S. Army Career Navigator</p>
      <p class="smart-banner-subtitle">Get it for Enhanced VIP Experience</p>
    </div>
    <button class="mobile-secondary small">Get</button>
  </div>
</div>

<div class="open" *ngIf='url === "/login"'>
  <div class="max-width cta-top-bar">
    <div class="logo">
      <a href="https://www.goarmy.com/?iom=AFOD-2675107_VIP_Website" target="_blank" class="goarmy-link"><img src="./assets/icons/army_logo2_rev_rgb.svg" alt="US Army Logo" class="army-logo"></a>
    </div>
    <div class="top-bar-text">
      <p class="smart-banner-subtitle">Open U.S. Army Career Navigator for Enhanced VIP Experience</p>
    </div>
    <button class="mobile-primary small">Open</button>
  </div>
</div>
