import { createAction } from '@ngrx/store';
export const Notify = createAction(
  '[NOTIFICATION] Notify',
  (payload = null) => ({ payload })
);
export const NotifySuccess = createAction(
  '[NOTIFICATION] Notify Success',
  (payload = null) => ({ payload })
);
export const NotifyError = createAction(
  '[NOTIFICATION] Notify Error',
  (payload = null) => ({ payload })
);
