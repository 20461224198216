import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutoLoginService } from '../../services/auto-login.service';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent implements OnInit {
  @Input()
  buttonLoading;

  @Output()
  resetPasswordInit = new EventEmitter();

  loginForm: FormGroup;

  validationMessages = {
    email: {
      email: 'Email address is invalid.',
      required: 'Email address is required.'
    }
  };

  constructor(
    private readonly formBuilder: FormBuilder,
    private autoLoginService: AutoLoginService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  isValid(): boolean {
    return this.loginForm.valid;
  }

  submit(): void {
    const { value, valid } = this.loginForm;

    if (valid) {
      this.resetPasswordInit.emit(value);
      this.autoLoginService.setUserInfo(value);
    }
  }

  submitOnEnter(event: { keyCode: number }): void {
    if (event.keyCode === 13) {
      this.submit();
    }
  }

  hasError(key: string): boolean {
    const control = this.loginForm.get(key);

    if (!control) {
      return false;
    }

    if ((!control.valid && control.touched) || control.untouched) {
      return control.errors ? true : false;
    }
  }

  getErrorMessage(key: string, types: Array<string>): any {
    const field = key;
    const control = this.loginForm.get(field);

    if (this.hasError(key)) {
      for (const errorType in types) {
        if (control.errors[types[errorType]]) {
          return this.validationMessages[key][types[errorType]];
        }
      }
    }
  }
}
