<p class="dashboard-card-subtitle">Account Settings</p>
<h2>Update Information</h2>
<form [formGroup]="vipNameForm">
  <mat-form-field [ngClass]="{'valid': vipNameForm.controls.username.valid && vipNameForm.value.username}" appearance="outline">
    <mat-label>VIP Name</mat-label>
    <input matInput formControlName="username">
    <mat-hint class="body-tiny">This name will appear on VIP leaderboards and achievements. <br> No profanity, please.</mat-hint>
    <mat-error *ngIf="hasError('username', 'vipNameForm')">{{ getErrorMessage('username', ['required', 'pattern', 'webPurify', 'tooLong', 'unknown'], 'vipNameForm') }}</mat-error>
  </mat-form-field>

  <div>
    <div class="spinner" *ngIf="userButtonLoading">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
    <button *ngIf="!userButtonLoading" class="army-button army-default" [ngClass]="{'army-default-inactive': !vipNameForm.valid || isSameUsername()}" [disabled]="!vipNameForm.valid || isSameUsername()" (click)="updateUsernameEmit()">Update VIP Name</button>
  </div>
</form>

<form [formGroup]="emailForm">
  <mat-form-field [ngClass]="{'valid': emailForm.controls.emailAddress.valid && emailForm.value.emailAddress}" appearance="outline">
    <mat-label>Email Address</mat-label>
    <input matInput formControlName="emailAddress">
    <mat-hint class="success" *ngIf="showEmailMessage">A confirmation email has been sent to your new email address. <span routerLink="/account/changeEmail/confirm">Enter confirmation code here.</span></mat-hint>
    <mat-hint class="body-tiny" *ngIf="!showEmailMessage">Enter your new email address here. We'll send you a confirmation code to verify it's you.</mat-hint>
    <mat-error *ngIf="hasError('emailAddress', 'emailForm')">{{ getErrorMessage('emailAddress', ['required', 'email'], 'emailForm') }}</mat-error>
  </mat-form-field>

  <div>
    <div class="spinner" *ngIf="emailButtonLoading">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
    <button *ngIf="!emailButtonLoading" class="army-button army-default" [ngClass]="{'army-default-inactive': !emailForm.valid || isSameEmail()}" [disabled]="!emailForm.valid || isSameEmail()" (click)="updateEmailEmit()">Update Email</button>
  </div>
</form>

<div class="footer">
  <button (click)="deleteDialog()" class="army-button army-text">Delete My Account</button>
  <button (click)="exportData()" class="army-button army-text">Export My Data</button>
</div>
