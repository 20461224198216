<div class="leaderboard-detail-container">
  <div class="crows-leaderboard">
    <div class="crows-leaderboard-header">
      <p class="dashboard-card-subtitle">Leaderboards</p>
    <h2>{{ currentLeaderboard?.name }}</h2>
    </div>

    <div class="crows-leaderboard-summary" [ngStyle]="{ 'background': 'url(' + currentLeaderboard?.backgroundImageURL + ')' }">
      <h6>{{ username }}</h6>
      <div class="crows-leaderboard-footer">
        <div class="position">
            <p class="caption">{{ notPlayed ? 'Participate to see your name on the leaderboard' : 'Your Position' }}</p>
            <p class="large-number">{{ notPlayed ? '' : currentLeaderboard?.leaderboards?.currentRank | number }}</p>
        </div>
        <div class="total">
            <p *ngIf="!notPlayed" class="caption">Out of</p>
            <p class="caption">{{ notPlayed ? '' : currentLeaderboard?.numberOfEntries | number }}</p>
        </div>
      </div>
    </div>

    <div class="crows-leaderboard-ranks">
      <div *ngFor="let leaders of topThree">
        <div class="rank-row" [ngClass]="{ 'currentUser': leaders.isPlayer }">
          <ng-container [ngSwitch]="leaders.rank">
            <div class="rank" *ngSwitchCase="1">
              <img src="../../../assets/icons/gold-badge.png" alt="" class="badge">
              <p class="caption top-three badge-number">{{ leaders.rank }}</p>
            </div>
            <div class="rank" *ngSwitchCase="2">
              <img src="../../../assets/icons/silver-badge.png" alt="" class="badge">
              <p class="caption top-three badge-number">{{ leaders.rank }}</p>
            </div>
            <div class="rank" *ngSwitchCase="3">
              <img src="../../../assets/icons/bronze-badge.png" alt="" class="badge">
              <p class="caption top-three badge-number">{{ leaders.rank }}</p>
            </div>
          </ng-container>

          <ng-container>
            <p class="caption name">{{ leaders.playerId }}</p>
            <p class="caption total">{{ leaders.score | number }}</p>
          </ng-container>
        </div>
      </div>

    <ng-container>
      <div class="divider" *ngIf="currentLeaderboard?.leaderboards?.currentRank > 10">
        <div class="border"></div>
        <hr class="dashed">
        <p class="body-tiny">Your Position</p>
      </div>

      <ng-container *ngFor="let leaders of relativeFive">
        <div class="rank-row" [ngClass]="{ 'currentUser': leaders.isPlayer }">
          <ng-container>
              <div class="rank default">
                  <p class="caption">{{ leaders.rank + '.' }}</p>
              </div>
            <p class="caption default-text">{{ leaders.playerId }}</p>
            <p class="caption total">{{ leaders.score | number }}</p>
          </ng-container>
          </div>
      </ng-container>
    </ng-container>
    <div class="border"></div>
</div>
