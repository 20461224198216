import { Component, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as fromStore from '../../store';
import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-settings-container',
  templateUrl: './settings-container.component.html',
  styleUrls: ['./settings-container.component.scss']
})
export class SettingsContainerComponent implements OnDestroy {
  userInfo$: Observable<any>;
  emailButtonLoading$: Observable<boolean>;
  userButtonLoading$: Observable<boolean>;
  showEmailMessage$: Observable<boolean>;
  buttonLoading$: Observable<boolean>;
  loggingOut$: Observable<boolean>;
  url: string;

  constructor(
    private store: Store<fromStore.AppState>,
    private router: Router
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.url = this.router.url;
        if (event.id === 1 && event.url === event.urlAfterRedirects) {
          this.store.dispatch(fromStore.GetUserDetails());
          this.store.dispatch(fromStore.RegisterButtonReset());
          this.userInfo$.subscribe((user) => {
            if (user) {
              this.store.dispatch(fromStore.GetCrowsLeaderboard());
            }
          });
        }
      });
    this.userInfo$ = this.store.pipe(select(fromStore.getUserInfo));
    this.emailButtonLoading$ = this.store.pipe(
      select(fromStore.isEmailButtonLoading)
    );
    this.userButtonLoading$ = this.store.pipe(
      select(fromStore.isUserButtonLoading)
    );
    this.showEmailMessage$ = this.store.pipe(select(fromStore.isEmailMessage));
    this.buttonLoading$ = this.store.pipe(select(fromStore.isButtonLoading));
    this.loggingOut$ = this.store.pipe(select(fromStore.isUserLoading));
  }

  logoutAttempt(): void {
    this.store.dispatch(fromStore.Logout());
  }

  exportData(): void {
    this.store.dispatch(fromStore.ExportUserData());
  }

  deleteAttempt(confirmDelete): void {
    if (confirmDelete) {
      this.store.dispatch(fromStore.DeleteUserAccount());
    }
  }

  usernameChange(data: { username: string }): void {
    this.store.dispatch(fromStore.ChangeUsername(data));
  }

  emailChange(data: { email: string }): void {
    this.store.dispatch(fromStore.ChangeEmail(data));
  }

  passwordChange(data: {
    newPassword: string;
    oldPassword: string;
    emailAddress: string;
  }): void {
    this.store.dispatch(fromStore.ChangePassword(data));
  }

  ngOnDestroy(): void {
    this.store.dispatch(fromStore.RegisterButtonReset());
  }
}
