import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-email-confirm',
  templateUrl: './change-email-confirm.component.html',
  styleUrls: ['./change-email-confirm.component.scss']
})
export class ChangeEmailConfirmComponent implements OnInit {
  @Input()
  isLoading: boolean;

  @Output()
  confirmationCode = new EventEmitter();

  confirmationForm: FormGroup;

  validationMessages = {
    confirmationCode: {
      required: 'The confirmation code is required.'
    }
  };

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.confirmationForm = this.formBuilder.group({
      confirmationCode: ['', Validators.required]
    });
  }

  isValid(): boolean {
    return this.confirmationForm.valid;
  }

  hasError(key: string): boolean {
    const control = this.confirmationForm.get(key);

    if (!control) {
      return false;
    }

    if ((!control.valid && control.touched) || control.untouched) {
      return control.errors ? true : false;
    }
  }

  getErrorMessage(key: string, types: Array<string>): any {
    const field = key;
    const control = this.confirmationForm.get(field);

    if (this.hasError(key)) {
      for (const errorType in types) {
        if (control.errors[types[errorType]]) {
          return this.validationMessages[key][types[errorType]];
        }
      }
    }
  }

  submit(): void {
    const { value, valid } = this.confirmationForm;

    if (valid) {
      this.confirmationCode.emit(value);
    }
  }

  submitOnEnter(event: { keyCode: number }): void {
    if (event.keyCode === 13) {
      this.submit();
    }
  }
}
