import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  @Input()
  isUserLoading: boolean;

  @Output()
  loginValues = new EventEmitter();

  loginForm: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(fromStore.ResetLogin());
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  isValid(): boolean {
    return this.loginForm.valid;
  }

  submit(): void {
    const { value, valid } = this.loginForm;

    if (valid) {
      this.loginValues.emit(value);
    }
  }

  submitOnEnter(event: { keyCode: number }): void {
    if (event.keyCode === 13) {
      this.submit();
    }
  }
}
