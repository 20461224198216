import { createAction } from '@ngrx/store';
export const GetAssets = createAction(
  '[ASSETS] Get Assets',
  (payload = null) => ({ payload })
);
export const GetAssetsSuccess = createAction(
  '[ASSETS] Get Assets Success',
  (payload = null) => ({ payload })
);
export const GetAssetsFailure = createAction(
  '[ASSETS] Get Assets Failure',
  (payload = null) => ({ payload })
);
