import { Component, OnInit, Input } from '@angular/core';
import { AchievementOverview } from '../../models/dashboard.model';

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.scss']
})
export class AchievementsComponent implements OnInit {
  @Input()
  interactions: any;

  @Input()
  achievements: any;

  completedObjectives = [];
  completedAchievements = [];

  ngOnInit(): void {
    if (this.achievements) {
      this.achievements.forEach((asset) => {
        asset.objectives.forEach((assetObjective) => {
          this.interactions.forEach((interactionObjective) => {
            if (assetObjective.identifier === interactionObjective.objective) {
              if (
                !this.completedObjectives.includes(
                  interactionObjective.objective
                )
              ) {
                this.completedObjectives.push(interactionObjective);
              }
            }
          });
        });
        if (this.getAssetNumberCompleted(asset) >= asset.objectives.length) {
          this.completedAchievements.push(asset);
        }
      });
    }
  }

  getAssetNumberCompleted(asset): number {
    const completedArray = [];
    this.completedObjectives.forEach((completedObjective) => {
      asset.objectives.forEach((objective) => {
        if (completedObjective.objective === objective.identifier) {
          completedArray.push(objective);
        }
      });
    });
    if (completedArray.length > asset.objectives.length) {
      return asset.objectives.length;
    } else {
      return completedArray.length;
    }
  }
}
