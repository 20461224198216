<div class="notifications-container max-width">
  <h1>Notifications</h1>

  <table>
    <tr routerLink="/notification/{{item.id}}" *ngFor="let item of notification">
      <td>{{item.title}}</td>
      <td class="date">{{item.date}}</td>
    </tr>
  </table>
</div>
