import { Component, OnInit } from '@angular/core';
import * as fromAuth from '../../store';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-welcome-form',
  templateUrl: './welcome-form.component.html',
  styleUrls: ['./welcome-form.component.scss']
})
export class WelcomeFormComponent implements OnInit {
  authStore$: Observable<any>;

  constructor(
    private authStore: Store<fromAuth.AppState>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authStore$ = this.authStore.pipe(select(fromAuth.isLoggedIn));
    if (this.authStore$) {
      this.authStore$.subscribe((loggedIn) => {
        if (loggedIn) {
          this.router.navigateByUrl('/dashboard');
        }
      });
    }
  }
}
