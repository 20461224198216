import * as fromActions from '../actions/asset.actions';
import { VIPAsset } from '../../models/assets.model';
import { Action, createReducer, on } from '@ngrx/store';

export interface AssetState {
  vipAssets: Array<VIPAsset>;
  loading: boolean;
}

export const initialState: AssetState = {
  vipAssets: null,
  loading: false
};

export const assetsReducer = createReducer(
  initialState,
  on(fromActions.GetAssets, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(fromActions.GetAssetsSuccess, (state, action) => {
    return {
      ...state,
      vipAssets: action.payload.VIPAssets,
      loading: false
    };
  }),
  on(fromActions.GetAssetsFailure, (state, action) => {
    return initialState;
  })
);

export function reducer(
  state: AssetState | undefined,
  action: Action
): AssetState {
  return assetsReducer(state, action);
}

export const isLoading = (state: AssetState) =>
  state && state.loading ? state.loading : false;
