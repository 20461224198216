import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromLeaderboards from '../reducers/leaderboard.reducer';

export const getLeaderboardData = createSelector(
  fromFeature.getLeaderboardDataState,
  (state: fromFeature.AppState) => (state ? state.leaderboardData : {})
);

export const leaderboardIsLoading = createSelector(
  fromFeature.getLeaderboardState,
  fromLeaderboards.isLoading
);
