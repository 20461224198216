<div class="sign-up-welcome max-width">
  <img src="./assets/icons/vip-icon-only-dark.png" alt="VIP Icon" class="vip-icon">

  <img src="./assets/icons/welcome-icon.svg" alt="Welcome Icon" class="welcome-icon">

  <h2>Welcome!</h2>

  <p class="body">Nulla vitae elit libero, a pharetra augue. Vestibulum id ligula.</p>

  <button [routerLink]="['/login']" class="army-button army-default">Sign In</button>

</div>
