<div class="welcome-container">
  <div class="welcome-form max-width">
    <img src="./assets/icons/VIP-Vert-Negative.svg" alt="VIP Icon" class="vip-icon">

    <h5>The U.S. Army Virtual Interactive Pass is your ticket to exclusive
      experiences inside the National Museum of the U.S. Army.
      Sign up now to become a U.S. Army VIP!
    </h5>

    <button [routerLink]="['/login']" class="army-button army-default">Sign In</button>

    <button [routerLink]="['/signup']" class="army-button army-text">Get Your Free Pass</button>

    <div class="welcome-footer">
      <a href="https://www.goarmy.com/?iom=AFOD-2675107_VIP_Website" target="_blank" class="goarmy-link"><img src="./assets/icons/army_logo1_rev_rgb.svg" alt="US Army Logo" class="army-logo"></a>
    </div>
  </div>
</div>
