import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-change-email-cancel',
  templateUrl: './change-email-cancel.component.html',
  styleUrls: ['./change-email-cancel.component.scss']
})
export class ChangeEmailCancelComponent implements OnInit {
  @Input()
  isLoggedIn;
  @Input()
  changeEmailLoading;
  @Input()
  cancelChangeEmailSuccess;
  @Input()
  errorMessage;

  @Output()
  cancelChangeEmailAttempt = new EventEmitter();
  @Output()
  goHome = new EventEmitter();

  appName: string;
  accountId: string;

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((params) => {
      const appName = 'appname';
      const accountId = 'accountid';
      this.appName = params[appName];
      this.accountId = params[accountId];
    });
  }

  ngOnInit(): void {
    if (this.appName) {
      if (this.appName.includes('VIP') && this.accountId) {
        this.cancelChangeEmailAttempt.emit(this.accountId);
      }
    }
  }

  formatErrorMessage(message) {
    if (message) {
      let errorMessage = message.replace('BadRequest: ', '');
      if (errorMessage.includes(':')) {
        errorMessage = errorMessage.split(':')[0];
      }
      return errorMessage;
    }
  }

  goHomeAttempt() {
    this.goHome.emit(this.isLoggedIn);
  }
}
