  <span class="spinner" *ngIf="(loggingOut$ | async) || ((userInfo$ | async) === false)"><mat-spinner></mat-spinner></span>
  <app-header></app-header>

  <div class="settings-container" *ngIf="(userInfo$ | async) && ((loggingOut$ | async) === false)">
    <div class="settings">

      <div *ngIf="url.includes('settings')">
        <h2>Account Settings</h2>
        <div class="full-row-a">
          <hr>
          <div class="option-row" routerLink="/update-info">
            <p class="caption">
              <span>Update Information</span></p>
              <img src="./assets/icons/chevron_right.svg" alt="Right Arrow Icon" class="material-icons right">
          </div>
        </div>
        <div class="full-row-b">
          <hr>
          <div class="option-row" routerLink="/update-password">
            <p class="caption">
              <span>Update Password</span></p>
              <img src="./assets/icons/chevron_right.svg" alt="Right Arrow Icon" class="material-icons right">
          </div>
          <hr>
        </div>
        <div class="logout-link">
          <button (click)="logoutAttempt()" class="army-button army-text">Sign Out</button>
        </div>
      </div>

      <app-update-info (deleteAttempt)="deleteAttempt($event)" (exportDataAttempt)="exportData()" (usernameChange)="usernameChange($event)" (emailChange)="emailChange($event)" [showEmailMessage]="showEmailMessage$ | async" [emailButtonLoading]="emailButtonLoading$ | async" [userButtonLoading]="userButtonLoading$ | async" [userInfo]="userInfo$ | async" *ngIf="url.includes('update-info')"></app-update-info>
      <app-update-password (passwordChange)="passwordChange($event)" [userInfo]="userInfo$ | async" [buttonLoading]="buttonLoading$ | async" *ngIf="url.includes('update-password')"></app-update-password>
    </div>
  </div>


