<div class="delayed-sign-up-form-container">

  <div class="delayed-signup-form max-width">

    <img [src]="assets?.iconImageURL" alt="Generic Logo" class="icon">
    <p class="caption">{{ assets?.name }}</p>

    <p class="caption">CREATE AN ACCOUNT</p>

    <form [formGroup]="delayedSignupFormGroup">
      <h2>Almost there! Complete your registration.</h2>
      <mat-form-field class="confirmation-field" [ngClass]="{'valid': delayedSignupFormGroup.controls.email.valid}" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email">
        <!-- <mat-error class="confirmation-error" *ngIf="hasError('confirmationCode')">{{getErrorMessage('confirmationCode', ['required', 'pattern'])}}<p class="caption">Having trouble? <span (click)="resendCode()">Get a New Code</span></p></mat-error>
        <mat-hint class="body-tiny">A confirmation code was sent to your email address. Check your email and enter the code here. <p class="caption">Having trouble? <span (click)="resendCode()">Get a New Code</span></p></mat-hint> -->
      </mat-form-field>

      <mat-form-field class="confirmation-field" [ngClass]="{'valid': delayedSignupFormGroup.controls.confirmationCode.valid}" appearance="outline">
        <mat-label>Confirmation Code</mat-label>
        <input matInput formControlName="confirmationCode">
        <!-- <mat-error class="confirmation-error" *ngIf="hasError('confirmationCode')">{{getErrorMessage('confirmationCode', ['required', 'pattern'])}}<p class="caption">Having trouble? <span (click)="resendCode()">Get a New Code</span></p></mat-error>
        <mat-hint class="body-tiny">A confirmation code was sent to your email address. Check your email and enter the code here. <p class="caption">Having trouble? <span (click)="resendCode()">Get a New Code</span></p></mat-hint> -->
      </mat-form-field>

        <mat-form-field [ngClass]="{'valid': delayedSignupFormGroup.controls.firstName.valid}" appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="firstName">
          <mat-error *ngIf="hasError('firstName')">{{getErrorMessage('firstName', ['required', 'pattern'])}}</mat-error>
        </mat-form-field>

        <mat-form-field [ngClass]="{'valid': delayedSignupFormGroup.controls.lastName.valid}" appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="lastName">
          <mat-error *ngIf="hasError('lastName')">{{getErrorMessage('lastName', ['required', 'pattern'])}}</mat-error>
        </mat-form-field>

        <mat-form-field [ngClass]="{'valid': delayedSignupFormGroup.controls.vipName.valid}" appearance="outline">
          <mat-label>VIP Name</mat-label>
          <input matInput formControlName="vipName">
          <mat-error *ngIf="hasError('vipName')">{{getErrorMessage('vipName', ['required', 'pattern', 'webPurify', 'tooLong', 'unknown'])}}</mat-error>
          <mat-hint class="body-tiny">This name will appear on VIP leaderboards and achievements. <br> No profanity, please.</mat-hint>
        </mat-form-field>

        <mat-form-field [ngClass]="{'valid': delayedSignupFormGroup.controls.password.valid}" appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput autocomplete="off" formControlName="password" type="password">
          <mat-error *ngIf="hasError('password')">{{getErrorMessage('password', ['required', 'pattern'])}}</mat-error>
        </mat-form-field>

        <mat-form-field [ngClass]="{'valid': delayedSignupFormGroup.controls.dateOfBirth.valid}" appearance="outline">
          <mat-label>Date of Birth</mat-label>
          <input matInput [matDatepicker]="birthDatePicker" formControlName="dateOfBirth" disabled>
          <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #birthDatePicker disabled="false" startView="multi-year" [startAt]="startDate"></mat-datepicker>
          <mat-error *ngIf="hasError('dateOfBirth')">{{getErrorMessage('dateOfBirth', ['required'])}}</mat-error>
        </mat-form-field>

        <mat-form-field [ngClass]="{'valid': delayedSignupFormGroup.controls.zipCode.valid}" appearance="outline">
          <mat-label>Zip Code</mat-label>
          <input matInput formControlName="zipCode" type="tel">
          <mat-error *ngIf="hasError('zipCode')">{{getErrorMessage('zipCode', ['required', 'pattern'])}}</mat-error>
        </mat-form-field>

        <mat-checkbox (change)="toggleChecked()" formControlName="formAgreement">
          <div class="caption">
            <p>By checking this block and submitting this form, I agree to receive communications from the U.S. Army (via email, phone, or text message) and to the conditions of the <a [routerLink]="" class="sm-primary">Waiver & Release of Liability</a>.</p>
            <br>
            <p>Privacy Act Notice: The above disclosure is voluntary. All information will be used strictly for recruiting purposes. The authority for the collection of this information is Title 10, United States Code, Sections 503, 505, 508, and 12102, and EO 9397. For more information, please review our <a [routerLink]="" class="sm-primary">Privacy & Security Notice</a>.</p>
          </div>
        </mat-checkbox>

        <div class="finish-button">
          <mat-spinner *ngIf="buttonLoading"  diameter="50"></mat-spinner>
          <button *ngIf="!buttonLoading" class="army-button army-default" [ngClass]="{'army-default-inactive': !(checkboxChecked && delayedSignupFormGroup.valid)}" [disabled]="!(checkboxChecked && delayedSignupFormGroup.valid)" (click)="submit()" type="button">Submit</button>
        </div>

      </form>
  </div>
</div>
