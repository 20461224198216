<span class="spinner" *ngIf="loading || errorLoading"><mat-spinner></mat-spinner></span>

<span *ngIf="!loading && !errorLoading">

<ng-container [ngSwitch]="getCurrentComponent(url)">
  <div *ngSwitchCase="'activity'">
    <app-activity [assets]="assetData.vipAssets" [activityInfo]="sortedActivities"></app-activity>
  </div>

  <div *ngSwitchCase="'activity-detail'">
    <app-activity-detail [assets]="assetData.vipAssets" [activityInfo]="userDetails.activities"></app-activity-detail>
  </div>

  <div *ngSwitchCase="'achievements'">
    <app-achievements [achievements]="getAchievementArray()" [interactions]="userDetails.interactions"></app-achievements>
  </div>

  <div *ngSwitchCase="'achievement-detail'">
    <app-achievement-detail [achievements]="getAchievementArray()" [interactions]="userDetails.interactions"></app-achievement-detail>
  </div>

  <div *ngSwitchCase="'objective-detail'">
    <app-objective-detail [objectives]="getObjectiveArray()" [interactions]="userDetails.interactions"></app-objective-detail>
  </div>

  <div *ngSwitchCase="'leaderboards'">
    <app-leaderboards [assets]="assetData.vipAssets" [username]="userDetails.userInfo.username" [leaderboards]="leaderboards"></app-leaderboards>
  </div>

  <div *ngSwitchCase="'leaderboard-detail'">
    <app-leaderboard-detail [assets]="assetData.vipAssets" [username]="userDetails.userInfo.username" [leaderboards]="leaderboards"></app-leaderboard-detail>
  </div>

  <div *ngSwitchDefault>

    <div class="dashboard-container">

      <div class="dashboard-header">
        <app-header></app-header>
        <h3>Hello <br> {{ userDetails?.userInfo?.username }}</h3>
      </div>

      <div class="dashboard-cards">

        <div class="dashboard-card-header">
          <button class="army-button army-text" [routerLink]="['/activity']">Activity Stream</button>
          <div *ngIf="swiper1Scrollable" class="desktop-dashboard-card-nav">
          <button class="mobile-secondary small" (click)="slideLeft('1')"><img src="./assets/icons/arrow_back.svg" alt="back arrow" class="material-icons right small-icon"></button>
          <button class="mobile-secondary small" (click)="slideRight('1')"><img src="./assets/icons/arrow_forward.svg" alt="forward arrow" class="material-icons left small-icon"></button>
        </div>

        </div>
        <div class="dashboard-card">
          <div class="swiper1">
            <div *ngFor="let activity of sortedActivities">
              <app-activity-card [assets]="assetData.vipAssets" [activityInfo]="activity" [routerLink]="['/activity-detail/' + activity.identifier]"></app-activity-card>
            </div>
          </div>
          <div *ngIf="!swiper1Scrollable" class="card-placeholder">
            <app-activity-card *ngFor="let i of [].constructor(12)"></app-activity-card>
          </div>
        </div>

        <div class="dashboard-card-header">
          <button class="army-button army-text" [routerLink]="['/achievements']">Achievements</button>
          <div *ngIf="swiper2Scrollable" class="desktop-dashboard-card-nav">
          <button class="mobile-secondary small" (click)="slideLeft('2')"><img src="./assets/icons/arrow_back.svg" alt="back arrow" class="material-icons right small-icon"></button>
          <button class="mobile-secondary small" (click)="slideRight('2')"><img src="./assets/icons/arrow_forward.svg" alt="forward arrow" class="material-icons left small-icon"></button>
        </div>
        </div>
        <div class="dashboard-card">
          <div class="swiper2">
            <div *ngFor="let achievement of achievementArray">
              <app-achievement-card [achievement]="achievement" [interactions]="userDetails?.interactions" [routerLink]="['/achievement-detail/' + achievement?.identifier]"></app-achievement-card>
            </div>
          </div>
          <div *ngIf="!swiper2Scrollable" class="card-placeholder">
            <app-achievement-card *ngFor="let i of [].constructor(12)"></app-achievement-card>
          </div>
        </div>

        <div class="dashboard-card-header">
          <button [routerLink]="['/leaderboards']" class="army-button army-text">Leaderboards</button>
          <div *ngIf="swiper3Scrollable" class="desktop-dashboard-card-nav">
          <button class="mobile-secondary small" (click)="slideLeft('3')"><img src="./assets/icons/arrow_back.svg" alt="back arrow" class="material-icons right small-icon"></button>
          <button class="mobile-secondary small" (click)="slideRight('3')"><img src="./assets/icons/arrow_forward.svg" alt="forward arrow" class="material-icons left small-icon"></button>
        </div>
        </div>
        <div class="dashboard-card">
          <div class="swiper3">
            <span  *ngIf="leaderboards?.crowsLeaderBoard">
                <app-leaderboard-card [assets]="assetData?.vipAssets" [crowsLeaderboard]="leaderboards?.crowsLeaderBoard" [username]="userDetails?.userInfo?.username" [routerLink]="['/leaderboard-detail/' + 'CROWS']"></app-leaderboard-card>
            </span>
        </div>
        <div *ngIf="!swiper3Scrollable" class="card-placeholder">
            <app-leaderboard-card *ngFor="let i of [].constructor(12)"></app-leaderboard-card>
          </div>
        </div>

      </div>
      <app-footer></app-footer>
    </div>
  </div>
</ng-container>
</span>


