import * as fromActions from '../actions';
import * as fromAuth from '../actions/auth.actions';
import { CrowsLeaderboard } from '../../models/leaderboards.model';
import { Action, createReducer, on } from '@ngrx/store';

export interface LeaderboardState {
  crowsLeaderBoard: CrowsLeaderboard;
  leaderBoardLoading: boolean;
}

export const initialState: LeaderboardState = {
  crowsLeaderBoard: null,
  leaderBoardLoading: false
};

export const leaderboardReducer = createReducer(
  initialState,
  on(fromActions.GetCrowsLeaderboard, (state, action) => {
    return {
      ...state,
      leaderBoardLoading: true
    };
  }),
  on(fromActions.GetCrowsLeaderboardSuccess, (state, action) => {
    return {
      ...state,
      crowsLeaderBoard: action.payload,
      leaderBoardLoading: false
    };
  }),
  on(fromActions.GetCrowsLeaderboardFailure, (state, action) => {
    return initialState;
  }),
  on(fromAuth.LogoutSuccess, (state, action) => {
    return initialState;
  })
);

export function reducer(
  state: LeaderboardState | undefined,
  action: Action
): LeaderboardState {
  return leaderboardReducer(state, action);
}

export const isLoading = (state: LeaderboardState) =>
  state && state.leaderBoardLoading ? state.leaderBoardLoading : false;
