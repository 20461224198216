import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { AssetsService } from '../../services/assets.service';
import * as actions from '../actions';

@Injectable()
export class AssetEffects {
  constructor(
    private actions$: Actions,
    private assetsService: AssetsService
  ) {}

  getAssets$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(actions.GetAssets)).pipe(
      exhaustMap(() =>
        this.assetsService.getAssets().pipe(
          map((res: any) => actions.GetAssetsSuccess(res)),
          catchError((err) => of(actions.GetAssetsFailure(err)))
        )
      )
    )
  );
}
