<div class="login-form-container">
  <div class="login-form max-width">
    <img src="./assets/icons/VIP-Vert-Negative.svg" alt="VIP Icon" class="vip-icon">

    <h5>Welcome Back!</h5>

    <form [formGroup]="loginForm" (keydown)="submitOnEnter($event)">
      <mat-form-field [ngClass]="{'valid': loginForm.controls.email.valid}" appearance="outline">
        <mat-label>Email Address</mat-label>
        <input formControlName="email" matInput>
      </mat-form-field>

      <mat-form-field [ngClass]="{'valid': loginForm.controls.password.valid}" appearance="outline">
        <mat-label>Password</mat-label>
        <input formControlName="password" matInput type="password">
      </mat-form-field>
    </form>

    <div class="spinner" *ngIf="isUserLoading">
      <mat-spinner diameter="48"></mat-spinner>
    </div>
    <button *ngIf="!isUserLoading" [routerLink]="['/login']" [ngClass]="{'army-default-inactive': !isValid()}" class="army-button army-default" (click)="submit()">Sign In</button>
    <div class="login-footer">
      <button [routerLink]="['/forgot-password']" class="army-button army-text">Forgot password</button>
      <button [routerLink]="['/signup']" class="army-button army-text sign-up">I don't have an account</button>
      <a href="https://www.goarmy.com/?iom=AFOD-2675107_VIP_Website" target="_blank" class="goarmy-link"><img src="./assets/icons/army_logo1_rev_rgb.svg" alt="US Army Logo" class="army-logo"></a>
    </div>
  </div>
</div>


