<div *ngIf="activityInfo" class="dashboard-card activity-bg"
[ngStyle]="{ 'background': getBackground() }">
  <div class="activity">
  <h5>{{ formatDate(activityInfo?.occurred) }}</h5>
  <h3>{{ activityInfo?.assetName }}</h3>
  </div>
  <div class="activity-footer">
    <div class="number">
      <img src="./assets/icons/hexagon.svg" alt="Hexagon Icon" class="hexagon-icon">
      <p>{{ activityInfo?.interactions?.length }}</p>
    </div>
    <p class="caption">Interactions</p>
    <img [src]="getIcon() || './assets/icons/vip-icon-only-dark.png'" alt="Logo" class="logo">
  </div>
</div>

<div *ngIf="!activityInfo" class="dashboard-card activity-bg empty">
</div>
