import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as fromAuth from '../../store';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AutoLoginService } from '../../services/auto-login.service';

@Component({
  selector: 'app-sign-up-container',
  templateUrl: './sign-up-container.component.html',
  styleUrls: ['./sign-up-container.component.scss']
})
export class SignUpContainerComponent implements OnInit {
  url: string;

  buttonLoading$: Observable<boolean>;

  email: string;
  password: string;

  constructor(
    private authStore: Store<fromAuth.AppState>,
    private router: Router,
    private autoLoginService: AutoLoginService
  ) {
    this.buttonLoading$ = this.authStore.pipe(select(fromAuth.isButtonLoading));
  }

  ngOnInit(): void {
    this.authStore.dispatch(fromAuth.RegisterButtonReset());
    this.url = this.router.url;
    this.email = this.autoLoginService.getEmail();
    this.password = this.autoLoginService.getPassword();
    if (!this.email && !this.password && this.url === '/signup-confirm') {
      this.router.navigateByUrl('/login');
    }
  }

  format(inputDate) {
    const date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      const month =
        date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      return day + '/' + month + '/' + date.getFullYear();
    }
  }

  verifyEmail(accountData): void {
    this.autoLoginService.setUserInfo({
      password: accountData.password,
      email: accountData.email
    });
    this.authStore.dispatch(fromAuth.VerifyEmail(accountData));
  }

  registerUser(signupData): void {
    const finalData = {
      email: this.email,
      confirmationCode: signupData.confirmationCode,
      firstName: signupData.firstName,
      lastName: signupData.lastName,
      username: signupData.vipName,
      zip: signupData.zipCode,
      dob: this.format(signupData.dateOfBirth),
      password: this.password
    };
    this.authStore.dispatch(fromAuth.Signup(finalData));
  }

  resendConfirmationCode() {
    this.authStore.dispatch(fromAuth.ResendConfirmationCode(this.email));
  }
}
