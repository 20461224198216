import { Injectable } from '@angular/core';

import { Action, Store, select } from '@ngrx/store';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import {
  map,
  exhaustMap,
  catchError,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import * as authActions from '../actions';
import { AuthService } from '../../services/auth.service';
import * as fromAuth from '../reducers/index';
import * as fromStore from '../actions';
import * as fromSelectors from '../selectors';
import { Router } from '@angular/router';
import { AutoLoginService } from '../../services/auto-login.service';

@Injectable()
export class AuthEffects {
  passwordChanged: boolean;
  emailChanged: boolean;
  usernameChanged: boolean;
  passwordSuccess: boolean;
  emailSuccess: boolean;
  usernameSuccess: boolean;
  usernameErrorMessage: string;
  emailErrorMessage: string;
  passwordErrorMessage: string;

  constructor(
    private store$: Store<fromAuth.AppState>,
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private autoLoginService: AutoLoginService
  ) {}

  loginAttempt$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(authActions.Login)).pipe(
      exhaustMap((action) =>
        this.authService.login(action.payload).pipe(
          map((res: any) => authActions.LoginSuccess(res)),
          catchError((err) => {
            if (err.error.errorType === 'UserNotConfirmedException') {
              this.autoLoginService.setUserInfo({
                password: action.payload.password,
                email: action.payload.email
              });
              this.router.navigateByUrl('/signup-confirm');
            }
            return of(authActions.LoginFailure(err));
          })
        )
      )
    )
  );

  loginSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.LoginSuccess)).pipe(
        tap(() => {
          this.router.navigateByUrl('/dashboard');
        })
      ),
    { dispatch: false }
  );

  logoutAttempt$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(authActions.Logout)).pipe(
      withLatestFrom(this.store$.pipe(select(fromSelectors.getIdToken))),
      exhaustMap(([action, token]) => {
        return this.authService.logout(token).pipe(
          map((res: any) =>
            authActions.LogoutSuccess({
              ...res
            })
          ),
          catchError((err) => of(authActions.LogoutFailure(err)))
        );
      })
    )
  );

  logoutSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.LogoutSuccess)).pipe(
        tap(() => {
          this.router.navigateByUrl('/login');
        })
      ),
    { dispatch: false }
  );

  signupAttempt$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(authActions.Signup)).pipe(
      exhaustMap((action) =>
        this.authService.signup(action.payload).pipe(
          map((res: any) => {
            if (action.payload.password) {
              this.store$.dispatch(
                authActions.Login({
                  email: action.payload.email,
                  password: action.payload.password
                })
              );
              return authActions.AutoLoginSignupSuccess();
            }
            return authActions.SignupSuccess(res);
          }),
          catchError((err) => of(authActions.SignupFailure(err)))
        )
      )
    )
  );

  delayedSignupAttempt$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(authActions.DelayedSignup)).pipe(
      exhaustMap((action) =>
        this.authService.delayedSignup(action.payload).pipe(
          map((res: any) => authActions.DelayedSignupSuccess(res)),
          catchError((err) => of(authActions.DelayedSignupFailure(err)))
        )
      )
    )
  );

  signupSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.SignupSuccess)).pipe(
        tap(() => {
          this.router.navigateByUrl('/');
        })
      ),
    { dispatch: false }
  );

  verifyEmail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(authActions.VerifyEmail)).pipe(
      exhaustMap((action) =>
        this.authService.verifyEmail(action.payload).pipe(
          map((res: any) => authActions.VerifyEmailSuccess(res)),
          catchError((err) => {
            if (
              err.error.errorType === 'AccountExistsException' ||
              err.error.errorType === 'AccountExistsNotVerifiedException'
            ) {
              this.router.navigateByUrl('/login');
            }
            return of(authActions.VerifyEmailFailure(err));
          })
        )
      )
    )
  );

  verifyEmailSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.VerifyEmailSuccess)).pipe(
        tap(() => {
          this.router.navigateByUrl('/signup-confirm');
        })
      ),
    { dispatch: false }
  );

  resetPasswordInit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(authActions.ResetPasswordInit)).pipe(
      exhaustMap((action) =>
        this.authService.resetPasswordInit(action.payload).pipe(
          map((res: any) => authActions.ResetPasswordInitSuccess(res)),
          catchError((err) => of(authActions.ResetPasswordInitFailure(err)))
        )
      )
    )
  );

  resetPasswordInitSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ResetPasswordInitSuccess)).pipe(
        tap(() => {
          this.router.navigateByUrl('/reset-password');
        })
      ),
    { dispatch: false }
  );

  resetPasswordComplete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(authActions.ResetPasswordComplete)).pipe(
      exhaustMap((action) =>
        this.authService.resetPasswordComplete(action.payload).pipe(
          map((res: any) => authActions.ResetPasswordCompleteSuccess(res)),
          catchError((err) => of(authActions.ResetPasswordCompleteFailure(err)))
        )
      )
    )
  );

  resetPasswordCompleteSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ResetPasswordCompleteSuccess)).pipe(
        tap(() => {
          this.router.navigateByUrl('/login');
        })
      ),
    { dispatch: false }
  );

  validateRefreshToken: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(authActions.ValidateRefreshToken)).pipe(
      exhaustMap((action) =>
        this.authService.validateRefreshToken(action.payload).pipe(
          map((res: any) => authActions.ValidateRefreshTokenSuccess(res)),
          catchError((err) => of(authActions.ValidateRefreshTokenFailure(err)))
        )
      )
    )
  );

  validateRefreshTokenSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ValidateRefreshTokenSuccess)).pipe(
        tap(() => {
          this.store$.dispatch(fromStore.GetUserDetails());
          this.store$.dispatch(fromStore.GetCrowsLeaderboard());
          this.store$.dispatch(fromStore.GetAssets());
          this.router.navigateByUrl('/dashboard');
        })
      ),
    { dispatch: false }
  );

  validateRefreshTokenFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ValidateRefreshTokenFailure)).pipe(
        tap(() => {
          this.router.navigateByUrl('/login');
        })
      ),
    { dispatch: false }
  );

  deleteAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(authActions.DeleteUserAccount)).pipe(
      withLatestFrom(this.store$.pipe(select(fromSelectors.getIdToken))),
      exhaustMap(([action, token]) =>
        this.authService.deleteUserAccount(token).pipe(
          map((res: any) => authActions.DeleteUserAccountSuccess(res)),
          catchError((err) => of(authActions.DeleteUserAccountFailure(err)))
        )
      )
    )
  );

  deleteAccountSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.DeleteUserAccountSuccess)).pipe(
        tap(() => {
          this.router.navigateByUrl('/login');
        })
      ),
    { dispatch: false }
  );

  exportUserData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(authActions.ExportUserData)).pipe(
      withLatestFrom(this.store$.pipe(select(fromSelectors.getIdToken))),
      exhaustMap(([action, token]) =>
        this.authService.exportUserData(token).pipe(
          map((res: any) => authActions.ExportUserDataSuccess(res)),
          catchError((err) => of(authActions.ExportUserDataFailure(err)))
        )
      )
    )
  );

  confirmChangeEmail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(authActions.ConfirmChangeEmail)).pipe(
      map((action) => action.payload),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.getIdToken)),
        this.store$.pipe(select(fromSelectors.getRefreshToken))
      ),
      exhaustMap(([action, idToken, refreshToken]) =>
        this.authService.confirmChangeEmail(action, idToken).pipe(
          map((res: any) => {
            this.store$.dispatch(
              authActions.ValidateRefreshToken(refreshToken)
            );
            return authActions.ConfirmChangeEmailSuccess(res);
          }),
          catchError((err) => of(authActions.ConfirmChangeEmailFailure(err)))
        )
      )
    )
  );

  cancelChangeEmail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(authActions.CancelChangeEmail)).pipe(
      exhaustMap((action) =>
        this.authService.cancelChangeEmail(action.payload).pipe(
          map((res: any) => authActions.CancelChangeEmailSuccess(res)),
          catchError((err) => of(authActions.CancelChangeEmailFailure(err)))
        )
      )
    )
  );

  changeUsername$: Observable<any> = createEffect(() =>
    this.actions$.pipe(ofType(authActions.ChangeUsername)).pipe(
      map((action) => action.payload),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getIdToken))),
      exhaustMap(([payload, idToken]) =>
        this.authService.changeUsername(idToken, payload).pipe(
          map((res: any) => fromStore.ChangeUsernameSuccess(res)),
          catchError((err) => of(fromStore.ChangeUsernameFailure(err)))
        )
      )
    )
  );

  changeEmail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(authActions.ChangeEmail)).pipe(
      map((action) => action.payload),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.getEmail)),
        this.store$.pipe(select(fromSelectors.getIdToken))
      ),
      exhaustMap(([payload, currentEmail, idToken]) =>
        this.authService.changeEmail(currentEmail, payload, idToken).pipe(
          map((res: any) => fromStore.ChangeEmailSuccess(res)),
          catchError((err) => of(fromStore.ChangeEmailFailure(err)))
        )
      )
    )
  );

  changePassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(authActions.ChangePassword)).pipe(
      map((action) => action.payload),
      withLatestFrom(
        this.store$.pipe(select(fromSelectors.getAccessToken)),
        this.store$.pipe(select(fromSelectors.getIdToken))
      ),
      exhaustMap(([payload, accessToken, idToken]) =>
        this.authService.changePassword(accessToken, idToken, payload).pipe(
          map((res: any) => fromStore.ChangePasswordSuccess(res)),
          catchError((err) => of(fromStore.ChangePasswordFailure(err)))
        )
      )
    )
  );

  resendConfirmationCode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(authActions.ResendConfirmationCode)).pipe(
      map((action) => action.payload),
      exhaustMap((payload) =>
        this.authService.resendConfirmationCode(payload).pipe(
          map((res: any) => authActions.ResendConfirmationCodeSuccess(res)),
          catchError((err) =>
            of(authActions.ResendConfirmationCodeFailure(err))
          )
        )
      )
    )
  );

  onInfoChangeSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$
        .pipe(
          ofType(
            authActions.ChangeEmailSuccess,
            authActions.ChangeUsernameSuccess
          )
        )
        .pipe(
          tap(() => {
            this.store$.dispatch(authActions.GetUserDetails());
            this.store$.dispatch(authActions.GetCrowsLeaderboard());
          })
        ),
    { dispatch: false }
  );
}
