<div class="activity-detail-container">
  <div class="nmusa-activity">
    <div class="nmusa-activity-header">
      <p class="dashboard-card-subtitle">Latest Interactions</p>
      <h2>{{ currentActivity?.activityName }}</h2>
    </div>
    <div *ngFor="let date of dateArray"  class="nmusa-activity-info">
      <hr>
      <p class="dashboard-card-subtitle date">{{ formatDateLong(date) }}</p>
        <div *ngFor="let activity of sortedInteractions">
          <div *ngIf="dateCheck(date, activity.occurred)" class="activity-row">
            <img [src]="getIcon(currentActivity?.asset)" alt="" class="icon">
            <p class="caption info">{{ activity.summary }}</p>
            <p class="caption">{{ formatDateShort(activity.occurred) }}</p>
          </div>
        </div>
    </div>
  </div>
</div>
