<div class="demo-page">
  <div class="demo-section">
    <h6 class="demo-header">Typography</h6>
    <h1>H1 – Curabitur blandit tempus porttitor Nullam quis risus eget urna.</h1>
    <h2>H2 – Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</h2>
    <h3>H3 – Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</h3>
    <h4>H4 – Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</h4>
    <h5>H5 – Maecenas sed diam eget risus varius blandit sit amet non magna. Nulla vitae elit libero, a pharetra augue.</h5>
    <h6>H6 – Maecenas sed diam eget risus varius blandit sit amet non magna. Nulla vitae elit libero, a pharetra augue.</h6>
    <p class="body">Body 1 – Nullam id dolor id nibh ultricies vehicula ut id elit. Nullam quis risus eget urna mollis ornare vel eu leo. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Curabitur blandit tempus porttitor. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam.</p>
    <p class="caption">Caption – Nullam id dolor id nibh ultricies vehicula ut id elit. Nullam quis risus eget urna mollis ornare vel eu leo. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Curabitur blandit tempus porttitor. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam.</p>
    <p class="body-tiny">Body Tiny – Nullam id dolor id nibh ultricies vehicula ut id elit. Nullam quis risus eget urna mollis ornare vel eu leo. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Curabitur blandit tempus porttitor. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam.</p>
    <p class="smart-banner-title">Smart Banner Title – U.S.Army Career Navigator</p>
    <p class="smart-banner-subtitle">Smart Banner Subtitle – U.S.Army Career Navigator</p>
    <a class="text-button lg-primary">Button Large Primary</a>
    <a class="text-button lg-secondary">Button Large Secondary</a>
    <a class="text-button md-primary">Button Medium Primary</a>
    <a class="text-button md-secondary">Button Medium Secondary</a>
    <a class="text-button sm-primary">Button Small Primary</a>
    <a class="text-button sm-secondary">Button Small Secondary</a>
    <p class="large-number">1,562</p>
    <p class="small-number">125</p>
  </div>

  <div class="demo-section">
    <h6 class="demo-header">Mobile Primary Buttons</h6>
    <p class="caption">Fluid buttons fill the width of the container they're in (366px container shown here).</p>

    <div class="fluid-buttons">
      <div class="col">
        <button class="mobile-fluid large">Fluid Large</button>
      </div>
      <div class="col">
        <button class="mobile-fluid large-emphasis">Fluid Large Emphasis</button>
      </div>
      <div class="col">
        <button class="mobile-fluid large-inactive">Fluid Large Inactive</button>
      </div>
      <div class="col">
        <button class="mobile-fluid medium">Fluid Medium</button>
      </div>
      <div class="col">
        <button class="mobile-fluid medium-emphasis">Fluid Medium Emphasis</button>
      </div>
      <div class="col">
        <button class="mobile-fluid medium-inactive">Fluid Medium Emphasis Inactive</button>
      </div>
    </div>

    <div class="primary-buttons">
      <div class="col">
        <button class="mobile-primary large">Large Primary</button>
      </div>
      <div class="col">
        <button class="mobile-primary large"><mat-icon class="material-icons big-icon">arrow_forward</mat-icon></button>
      </div>
      <div class="col">
        <button class="mobile-primary large icon-text">Next <mat-icon class="material-icons left">arrow_forward</mat-icon></button>
      </div>
      <div class="col">
        <button class="mobile-primary medium">Medium Primary</button>
      </div>
      <div class="col">
        <button class="mobile-primary small">Small Primary</button>
      </div>
      <div class="col">
        <button class="mobile-primary small-inactive">Small Primary Inactive</button>
      </div>
      <div class="col">
        <button class="mobile-primary small-flat">Small Flat</button>
      </div>
      <div class="col">
        <button class="mobile-primary small-flat-inactive">Small Flat Inactive</button>
      </div>
    </div>

    <h6 class="demo-header">Mobile Secondary Buttons</h6>
    <div class="secondary-buttons">
      <div class="col">
        <button class="mobile-secondary large">Large Secondary</button>
      </div>
      <div class="col">
        <button class="mobile-secondary large icon-text"><mat-icon class="material-icons right">arrow_backward</mat-icon> Previous</button>
      </div>
      <div class="col">
        <button class="mobile-secondary large-inactive">Large Secondary Inactive</button>
      </div>
      <div class="col">
        <button class="mobile-secondary medium">Medium Secondary</button>
      </div>
      <div class="col">
        <button class="mobile-secondary medium icon-text"><mat-icon class="material-icons right">arrow_backward</mat-icon> Text + Icon</button>
      </div>
      <div class="col">
        <div class="secondary-fluid">
          <button class="mobile-secondary medium-fluid">Fluid Medium Secondary</button>
        </div>
      </div>
      <div class="col">
        <button class="mobile-secondary medium-flat">Medium Flat</button>
      </div>
      <div class="col">
        <button class="mobile-secondary medium-inactive">Medium Inactive</button>
      </div>
      <div class="col">
        <button class="mobile-secondary medium-flat-inactive">Medium Flat Inactive</button>
      </div>
      <div class="col">
        <button class="mobile-secondary small">Small Secondary</button>
      </div>
      <div class="col">
        <button class="mobile-secondary small"><mat-icon class="material-icons right small-icon">arrow_backward</mat-icon></button>
      </div>
      <div class="col">
        <button class="mobile-secondary small-inactive">Small Secondary Inactive</button>
      </div>
    </div>

  </div>

  <h6 class="demo-header">Updated Army Branding Buttons</h6>
  <div class="army-buttons">
    <div class="col">
      <button class="army-button army-default">Primary</button>
    </div>
    <div class="col">
      <button class="army-button army-default army-default-inactive">Primary Inactive</button>
    </div>
    <div class="col">
      <button class="army-button army-secondary">Secondary</button>
    </div>
    <div class="col">
      <button class="army-button army-secondary army-secondary-inactive">Secondary Inactive</button>
    </div>
    <div class="col">
      <button class="army-button army-text">Text Button</button>
    </div>
    <div class="col">
      <button class="army-button army-text army-text-inactive">Text Button Inactive</button>
    </div>
    <div class="col">
      <button class="army-arrow"><mat-icon class="material-icons right small-icon">arrow_backward</mat-icon></button>
    </div>
    <div class="col">
      <button class="army-arrow army-arrow-inactive"><mat-icon class="material-icons right small-icon">arrow_backward</mat-icon></button>
    </div>
  </div>

  <h6 class="demo-header">Form Inputs</h6>
  <p class="caption">Form elements from Invision can be seen here. Type in an email incorrectly to test for errors.</p>

  <div class="demo-section">
    <form>
      <mat-form-field appearance="outline">
        <mat-label>Test</mat-label>
        <input matInput [formControl]="email">
        <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field>
    </form>
  </div>

</div>



