import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent {
  notification = [
    {
      id: 1,
      title:
        'Cras justo odio, dapibus ac facilisis in, egestas eget quam. Cras justo odio...',
      date: '2d'
    },
    {
      id: 2,
      title:
        'Cras justo odio, dapibus ac facilisis in, egestas eget quam. Cras justo odio...',
      date: '9d'
    }
  ];
}
