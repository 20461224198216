import { createAction } from '@ngrx/store';
export const GetCrowsLeaderboard = createAction(
  '[LEADERBOARD] Get Crows Leaderboard',
  (payload = null) => ({ payload })
);
export const GetCrowsLeaderboardSuccess = createAction(
  '[LEADERBOARD] Get Crows Leaderboard Success',
  (payload = null) => ({ payload })
);
export const GetCrowsLeaderboardFailure = createAction(
  '[LEADERBOARD] Get Crows Leaderboard Failure',
  (payload = null) => ({ payload })
);
