import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnDestroy,
  OnChanges
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import * as CustomValidators from '../../validators';
import { Location } from '@angular/common';
import { Store, select } from '@ngrx/store';
import * as fromStore from '../../store';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { UsernameValidator } from '../../validators/username-validator';
import { UserDetailsState } from '../../store/reducers/userDetails.reducer';
import { UserInfo } from '../../models/userDetails.model';

@Component({
  selector: 'app-update-info',
  templateUrl: './update-info.component.html',
  styleUrls: ['./update-info.component.scss']
})
export class UpdateInfoComponent implements OnInit {
  @Input()
  userInfo: any;

  @Input()
  showEmailMessage: boolean;

  @Input()
  emailButtonLoading: boolean;

  @Input()
  userButtonLoading: boolean;

  @Output()
  usernameChange = new EventEmitter<any>();

  @Output()
  emailChange = new EventEmitter<any>();

  @Output()
  exportDataAttempt = new EventEmitter<any>();

  @Output()
  deleteAttempt = new EventEmitter<any>();

  vipNameForm: FormGroup;
  emailForm: FormGroup;

  emailSubmitted: boolean;
  usernameSubmitted: boolean;

  validationMessages = {
    emailAddress: {
      email: 'Email address is invalid.',
      required: 'Email address is required.'
    },
    username: {
      required: 'VIP name is required.',
      pattern: 'VIP name is invalid.',
      webPurify: 'Username can not contain profanity.',
      tooLong: 'Username must be less than 15 characters.',
      unknown: 'Unknown error. Please try again.'
    }
  };

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private location: Location,
    private store: Store<fromStore.AppState>,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.vipNameForm = this.formBuilder.group({
      username: [
        '',
        [Validators.required, Validators.pattern(/^\S*$/)],
        UsernameValidator.createValidator(this.authService)
      ]
    });
    this.emailForm = this.formBuilder.group({
      emailAddress: ['', [Validators.email, Validators.required]]
    });
    if (this.userInfo) {
      this.vipNameForm.patchValue({
        username: this.userInfo.username
      });
      this.emailForm.patchValue({
        emailAddress: this.userInfo.emailAddress
      });
    }
  }

  hasError(key: string, form: string): boolean {
    const submitted = this[form].controls.username
      ? this.usernameSubmitted
      : this.emailSubmitted;
    const control = this[form].get(key);

    if (!control) {
      return false;
    }

    if (
      (!control.valid && control.touched) ||
      (control.untouched && submitted)
    ) {
      return control.errors ? true : false;
    }
  }

  getErrorMessage(key: string, types: Array<string>, form: string): any {
    const field = key;
    const control = this[form].get(field);

    if (this.hasError(key, form)) {
      for (const errorType in types) {
        if (control.errors[types[errorType]]) {
          return this.validationMessages[key][types[errorType]];
        }
      }
    }
  }

  isSameUsername(): boolean {
    return this.vipNameForm.value.username === this.userInfo.username;
  }

  isSameEmail(): boolean {
    return this.emailForm.value.emailAddress === this.userInfo.emailAddress;
  }

  back(): void {
    this.location.back();
  }

  updateEmailEmit(): void {
    this.emailSubmitted = true;
    this.emailChange.emit(this.emailForm.value);
  }

  updateUsernameEmit(): void {
    this.usernameSubmitted = true;
    this.usernameChange.emit(this.vipNameForm.value);
  }

  exportData(): void {
    this.exportDataAttempt.emit();
  }

  deleteDialog(): void {
    this.dialog
      .open(DeleteModalComponent, {
        maxWidth: '100vw',
        width: '100%'
      })
      .afterClosed()
      .subscribe((confirmDelete) => {
        this.deleteAttempt.emit(confirmDelete);
      });
  }
}
