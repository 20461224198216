import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { UsernameValidator } from '../../validators/username-validator';

@Component({
  selector: 'app-sign-up-confirm-form',
  templateUrl: './sign-up-confirm-form.component.html',
  styleUrls: ['./sign-up-confirm-form.component.scss']
})
export class SignUpConfirmFormComponent implements OnInit {
  @Input()
  email: string;

  @Input()
  buttonLoading: boolean;

  @Output()
  signupData = new EventEmitter<any>();

  @Output()
  resendConfirmationCode = new EventEmitter<any>();

  signupConfirmFormGroup: FormGroup;

  checkboxChecked = false;

  currentYear = formatDate(new Date(), 'yyyy', 'en');
  startDate = new Date(Number(this.currentYear) - 10, 0, 1);

  validationMessages = {
    firstName: {
      required: 'First name is required.',
      pattern: 'First name is invalid.'
    },
    lastName: {
      required: 'Last name is required.',
      pattern: 'Last name is invalid.'
    },
    vipName: {
      required: 'VIP name is required.',
      pattern: 'VIP name is invalid.',
      webPurify: 'Username can not contain profanity.',
      tooLong: 'Username must be less than 15 characters.',
      unknown: 'Unknown error. Please try again.'
    },
    confirmationCode: {
      required: 'Confirmation code is required.',
      pattern: 'Confirmation code is invalid.'
    },
    dateOfBirth: {
      required: 'Date of birth is required.'
    },
    zipCode: {
      required: 'Zip code is required.',
      pattern: 'Zip code is invalid.'
    }
  };

  constructor(
    private readonly formBuilder: FormBuilder,
    private authService: AuthService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.signupConfirmFormGroup = this.formBuilder.group({
      email: [''],
      firstName: ['', [Validators.required, Validators.pattern(/^\S*$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^\S*$/)]],
      vipName: [
        '',
        [Validators.required, Validators.pattern(/^\S*$/)],
        UsernameValidator.createValidator(this.authService)
      ],
      dateOfBirth: ['', [Validators.required]],
      zipCode: [
        '',
        [Validators.required, Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)]
      ],
      formAgreement: [''],
      confirmationCode: [
        '',
        [Validators.required, Validators.pattern(/^[0-9]*$/)]
      ]
    });
    this.signupConfirmFormGroup.patchValue({ email: this.email });
  }

  toggleChecked() {
    this.checkboxChecked = !this.checkboxChecked;
  }

  hasError(key: string, group?: string): boolean {
    const field = group ? `${group}.${key}` : key;
    const control = this.signupConfirmFormGroup.get(field);

    if (!control) {
      return false;
    }

    if ((!control.valid && control.touched) || control.untouched) {
      return control.errors ? true : false;
    }
  }

  getErrorMessage(key: string, types: Array<string>, group?: string): any {
    const field = group ? `${group}.${key}` : key;
    const control = this.signupConfirmFormGroup.get(field);

    if (this.hasError(key, group)) {
      for (const errorType of types) {
        if (control.errors[errorType]) {
          return this.validationMessages[key][errorType];
        }
      }
    }
  }

  formatDateOfBirth(dateOfBirth): string {
    const date = formatDate(dateOfBirth, 'yyyy-MM-dd', 'en-US');
    return date;
  }

  submit(): void {
    if (this.signupConfirmFormGroup.invalid) {
      return;
    }
    const form = this.signupConfirmFormGroup.value;
    this.signupData.emit(form);
  }

  resendCode() {
    this.resendConfirmationCode.emit();
  }
}
