import {
  transition,
  trigger,
  query,
  style,
  animate,
  group
} from '@angular/animations';

// Define routing. Must define routes in app.module.ts.

export const backwardAnimationRoutes =
  'settings => dashboard, \
  settings => leaderboards, \
  settings => achievements, \
  settings => activity, \
  settings => activity-detail, \
  settings => achievement-detail, \
  settings => objective-detail, \
  settings => leaderboard-detail, \
  leaderboards => dashboard, \
  achievements => dashboard, \
  activity => dashboard, \
  activity-detail => dashboard, \
  achievement-detail => dashboard, \
  objective-detail => dashboard, \
  leaderboard-detail => dashboard, \
  activity-detail => activity, \
  leaderboard-detail => leaderboards, \
  achievement-detail => achievements, \
  objective-detail => achievement-detail, \
  signup => login, \
  signup-confirm => signup, \
  update-info => *, \
  update-password => *';

export const forwardAnimationRoutes =
  'dashboard => settings, \
  leaderboards => settings, \
  achievements => settings, \
  activity => settings, \
  activity-detail => settings, \
  achievement-detail => settings, \
  leaderboard-detail => settings, \
  objective-detail => settings, \
  settings => email-confirm, \
  dashboard => leaderboards, \
  dashboard => achievements, \
  dashboard => activity, \
  dashboard => activity-detail, \
  dashboard => achievement-detail, \
  dashboard => objective-detail, \
  dashboard => leaderboard-detail, \
  activity => activity-detail, \
  achievements => achievement-detail, \
  achievement-detail => objective-detail, \
  leaderboards => leaderboard-detail, \
  welcome => signup, \
  welcome => login, \
  signup-welcome => login, \
  login => signup, \
  * => update-info, \
  * => update-password';

export const slideInAnimation = trigger('routeAnimations', [
  // Backward Animation

  transition(
    backwardAnimationRoutes,
    [
      query(':enter, :leave', style({ position: 'fixed', width: '100%' }), {
        optional: true
      }),
      group([
        query(
          ':enter',
          [
            style({
              position: 'fixed',
              transform: 'translateX(-100%)',
              top: 0
            }),
            animate(
              '0.25s ease-in-out',
              style({ transform: 'translateX(0%)', top: 0 })
            )
          ],
          { optional: true }
        ),
        query(
          ':leave',
          [
            style({
              position: 'relative',
              transform: 'translateX(0%)'
            }),
            animate(
              '0.25s ease-in-out',
              style({ transform: 'translateX(100%)' })
            )
          ],
          { optional: true }
        )
      ])
    ],
    { params: { location: 0 } }
  ),

  // Forward Animation

  transition(
    forwardAnimationRoutes,
    [
      query(':enter, :leave', style({ position: 'fixed', width: '100%' }), {
        optional: true
      }),
      group([
        query(
          ':enter',
          [
            style({ position: 'fixed', transform: 'translateX(100%)', top: 0 }),
            animate(
              '0.25s ease-in-out',
              style({ transform: 'translateX(0%)', top: 0 })
            )
          ],
          { optional: true }
        ),
        query(
          ':leave',
          [
            style({
              position: 'relative',
              transform: 'translateX(0%)'
            }),
            animate(
              '0.25s ease-in-out',
              style({ transform: 'translateX(-100%)' })
            )
          ],
          { optional: true }
        )
      ])
    ],
    { params: { location: 0 } }
  )
]);
