import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromAssets from '../reducers/assets.reducer';

export const getAssetData = createSelector(
  fromFeature.getAssetDataState,
  (state: fromFeature.AppState) => (state ? state.assetData : {})
);

export const isLoadingAssets = createSelector(
  fromFeature.getAssetState,
  fromAssets.isLoading
);
