<div class="leaderboards-container">
  <div class="leaderboards">
    <div class="leaderboard-header">
      <h2>Leaderboards</h2>
      <button class="mobile-secondary small">{{ leaderboardArray.length || 'No' }} {{ leaderboardArray.length === 1 ? 'Active Board' : 'Active Boards' }}</button>
    </div>
    <div class="leaderboard-cards">
      <div *ngFor="let leaderboard of leaderboardArray" class="leaderboard-card" [ngStyle]="{ 'background': 'url(' + leaderboard?.backgroundImageURL + ')' }" [routerLink]="['/leaderboard-detail/' + leaderboard.leaderboard.identifier]">
        <div class="leaderboard-card-header">
        <h6>{{ leaderboard.name }}</h6>
          <img [src]="leaderboard?.iconImageURL" alt="Logo" class="logo">
        </div>

        <div class="leaderboard-card-footer">
          <div class="position">
            <p class="caption">{{ notPlayed ? 'Participate to see your name on the leaderboard' : 'Your Position' }}</p>
            <p class="large-number">{{ notPlayed ? '' : leaderboard.playerPosition.rank | number }}</p>
          </div>
          <div class="total">
              <p *ngIf="!notPlayed" class="caption">Out of</p>
              <p class="caption">{{ notPlayed ? '' : leaderboard?.numberOfEntries | number }}</p>
          </div>
        </div>
      </div>
      <div *ngFor="let i of [].constructor(6 - leaderboardArray.length)" class="achievement-card empty-bg">
      </div>
    </div>

  </div>

</div>
