import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {
  AchievementOverview,
  AchievementType
} from '../../models/dashboard.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-achievement-detail',
  templateUrl: './achievement-detail.component.html',
  styleUrls: ['./achievement-detail.component.scss']
})
export class AchievementDetailComponent implements OnChanges {
  @Input()
  interactions: any;

  @Input()
  achievements: any;

  assetId: string;
  currentAchievement: any;
  completedObjectives = [];

  constructor(private activatedRoute: ActivatedRoute) {
    this.assetId = String(this.activatedRoute.snapshot.paramMap.get('id'));
  }

  ngOnChanges(): void {
    if (this.achievements) {
      this.currentAchievement = this.achievements.find((achievement) => {
        return achievement.identifier === this.assetId;
      });
    }
    this.currentAchievement.objectives.forEach((objective) => {
      this.interactions.forEach((interaction) => {
        if (interaction.objective === objective.identifier) {
          if (!this.completedObjectives.includes(interaction.objective)) {
            this.completedObjectives.push(interaction.objective);
          }
        }
      });
    });
  }

  getProgressPercent(): number {
    if (this.currentAchievement) {
      const percent =
        (this.completedObjectives.length /
          this.currentAchievement.objectives.length) *
        100;
      return Math.floor(percent);
    }
  }
}
