import { Component, OnInit, Input } from '@angular/core';
import { AchievementType } from '../../models/dashboard.model';

@Component({
  selector: 'app-achievement-card',
  templateUrl: './achievement-card.component.html',
  styleUrls: ['./achievement-card.component.scss']
})
export class AchievementCardComponent {
  @Input()
  interactions: any;

  @Input()
  achievement: any;

  progressPercent: number;

  getProgressPercent(): number {
    if (this.interactions && this.achievement) {
      const totalObjectives = this.achievement.objectives.length;
      const completedObjectives = [];
      this.interactions.forEach((interaction) => {
        this.achievement.objectives.forEach((objective) => {
          if (interaction.objective === objective.identifier) {
            if (!completedObjectives.includes(interaction.objective)) {
              completedObjectives.push(interaction.objective);
            }
          }
        });
      });
      const percent = (completedObjectives.length / totalObjectives) * 100;
      return Math.floor(percent);
    }
  }
}
