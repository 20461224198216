import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromAuth from '../../store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss']
})
export class LoginContainerComponent implements OnInit, AfterViewInit {
  authStore$: Observable<boolean>;
  url: string;

  isUserLoading$: Observable<boolean>;
  buttonLoading$: Observable<boolean>;

  constructor(
    private authStore: Store<fromAuth.AppState>,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    this.url = router.url;
    this.authStore$ = this.authStore.pipe(select(fromAuth.isLoggedIn));
    this.isUserLoading$ = this.authStore.pipe(select(fromAuth.isUserLoading));
    this.buttonLoading$ = this.authStore.pipe(select(fromAuth.isButtonLoading));
  }

  ngOnInit(): void {
    this.authStore.dispatch(fromAuth.RegisterButtonReset());
    if (this.authStore$) {
      this.authStore$.subscribe((loggedIn) => {
        if (
          loggedIn &&
          !(
            this.url.includes('forgot-password') ||
            this.url.includes('reset-password')
          )
        ) {
          this.router.navigateByUrl('/dashboard');
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  login(loginValues): void {
    this.authStore.dispatch(fromAuth.Login(loginValues));
  }

  resetPasswordInit(emailAddress): void {
    this.authStore.dispatch(fromAuth.ResetPasswordInit(emailAddress));
  }

  resetPasswordComplete(data): void {
    this.authStore.dispatch(fromAuth.ResetPasswordComplete(data));
  }
}
