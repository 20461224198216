import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ActivityStream, Activity } from '../../models/dashboard.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-activity-detail',
  templateUrl: './activity-detail.component.html',
  styleUrls: ['./activity-detail.component.scss']
})
export class ActivityDetailComponent implements OnChanges {
  @Input()
  activityInfo: Array<any>;

  @Input()
  assets: any;

  activityId: any;
  currentActivity: any;
  sortedInteractions = [];
  dateArray = [];

  constructor(private activatedRoute: ActivatedRoute) {
    this.activityId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnChanges(): void {
    if (this.activityInfo) {
      this.currentActivity = this.activityInfo.find((activity) => {
        return activity.identifier === this.activityId;
      });
      this.currentActivity.interactions.forEach((activity) => {
        const date = activity.occurred.split('T')[0];
        if (!this.dateArray.includes(date)) {
          this.dateArray.push(date);
        }
        this.sortedInteractions.push(activity);
      });
      this.dateArray.sort((a, b) => b.localeCompare(a));
      this.sortedInteractions.sort(this.sortInteractions);
    }
  }

  dateCheck(dateGroup, activityDate): boolean {
    const formattedDate = activityDate.split('T')[0];
    if (dateGroup === formattedDate) {
      return true;
    }
  }

  getAsset(type) {
    if (type && this.assets) {
      let currentAsset;
      this.assets.forEach((asset) => {
        if (type.includes(asset.identifier)) {
          currentAsset = asset;
        }
      });
      return currentAsset;
    }
  }

  getIcon(type): string {
    const asset = this.getAsset(type);
    return asset.iconImageURL;
  }

  formatDateLong(dateString: string): string {
    const date = new Date(dateString);
    const monthDay = date.toLocaleString('default', {
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC'
    });
    const year = date.toLocaleString('default', { year: 'numeric' });
    const fullDate = `${monthDay}, ${year}`;
    return fullDate;
  }

  formatDateShort(dateString: string): string {
    const date = new Date(dateString);
    const duration = new Date().getTime() - date.getTime();
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(duration / (1000 * 60 * 60 * 24) / 7);

    if (weeks > 0) {
      return `${weeks}w`;
    } else if (days > 0) {
      return `${days}d`;
    } else if (hours > 0) {
      return `${hours}h`;
    } else {
      return 'Recent';
    }
  }

  sortInteractions(a: any, b: any) {
    const interactionA = a.occurred;
    const interactionB = b.occurred;

    let comparison = 0;
    if (interactionA > interactionB) {
      comparison = -1;
    } else if (interactionA < interactionB) {
      comparison = 1;
    }
    return comparison;
  }
}
