import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ActivityStream, Activity } from '../../models/dashboard.model';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnChanges {
  @Input()
  activityInfo: Array<any>;

  @Input()
  assets: any;

  dateArray = [];
  allActivities: Array<any> = [];
  sortedActivities: Array<any> = [];

  ngOnChanges(): void {
    if (this.activityInfo) {
      this.activityInfo.forEach((group) => {
        group.interactions.forEach((interaction) => {
          if (
            !this.allActivities.find(
              (act) => interaction.identifier === act.identifier
            )
          ) {
            const interactionObj = {
              ...interaction,
              asset: group.asset
            };
            this.allActivities.push(interactionObj);
          }
        });
      });
      this.allActivities.forEach((activity) => {
        const date = activity.occurred.split('T')[0];
        if (!this.dateArray.includes(date)) {
          this.dateArray.push(date);
        }
      });
    }
    this.dateArray.sort((a, b) => b.localeCompare(a));
    this.allActivities.sort((a, b) => b.occurred.localeCompare(a.occurred));
  }

  getAsset(type) {
    if (type && this.assets) {
      let currentAsset;
      this.assets.forEach((asset) => {
        if (type.includes(asset.identifier)) {
          currentAsset = asset;
        }
      });
      return currentAsset;
    }
  }

  getIcon(type): string {
    const asset = this.getAsset(type);
    return asset.iconImageURL;
  }

  dateCheck(dateGroup, activityDate): boolean {
    const formattedDate = activityDate.split('T')[0];
    if (dateGroup === formattedDate) {
      return true;
    }
  }

  formatDateLong(dateString: string): string {
    const date = new Date(dateString);
    const monthDay = date.toLocaleString('default', {
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC'
    });
    const year = date.toLocaleString('default', { year: 'numeric' });
    const fullDate = `${monthDay}, ${year}`;
    return fullDate;
  }

  formatDateShort(dateString: string): string {
    const date = new Date(dateString);
    const duration = new Date().getTime() - date.getTime();
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(duration / (1000 * 60 * 60 * 24) / 7);

    if (weeks > 0) {
      return `${weeks}w`;
    } else if (days > 0) {
      return `${days}d`;
    } else if (hours > 0) {
      return `${hours}h`;
    } else {
      return 'Recent';
    }
  }
}
