import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromAuth from './user.reducer';
import * as fromUserDetails from './userDetails.reducer';
import * as fromLeaderboards from './leaderboard.reducer';
import * as fromAssets from './assets.reducer';

export interface AppState {
  user: fromAuth.UserState;
  userDetailsData: fromUserDetails.UserDetailsState;
  leaderboardData: fromLeaderboards.LeaderboardState;
  assetData: fromAssets.AssetState;
}

export const reducers: ActionReducerMap<AppState> = {
  user: fromAuth.reducer,
  userDetailsData: fromUserDetails.reducer,
  leaderboardData: fromLeaderboards.reducer,
  assetData: fromAssets.reducer
};

export const getAuthState = (state: AppState) => state.user;
export const getUserDetailsState = (state: AppState) => state.userDetailsData;
export const getLeaderboardState = (state: AppState) => state.leaderboardData;
export const getAssetState = (state: AppState) => state.assetData;

export const getDashboardDataState =
  createFeatureSelector<AppState>('dashboard');
export const getUserDetailsDataState =
  createFeatureSelector<AppState>('userDetails');
export const getLeaderboardDataState =
  createFeatureSelector<AppState>('leaderboard');
export const getAssetDataState = createFeatureSelector<AppState>('assets');
export const getUserState = createFeatureSelector<AppState>('auth');
