import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { first, switchMap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '../store';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthGuard implements CanActivate {
  token$: Observable<string>;
  refreshToken$: Observable<string>;
  refreshToken: string;

  constructor(
    private store: Store<fromStore.AppState>,
    private router: Router
  ) {
    this.token$ = this.store.select(fromStore.getIdToken);
    this.refreshToken$ = this.store.select(fromStore.getRefreshToken);
    this.refreshToken$.subscribe((token) => {
      this.refreshToken = token;
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const queryParams = route.queryParams;

    if (
      queryParams &&
      queryParams['refreshToken'] &&
      queryParams['idToken'] &&
      queryParams['accessToken']
    ) {
      const refToken = queryParams['refreshToken'];
      const idToken = queryParams['idToken'];
      const accessToken = queryParams['accessToken'];

      const referralData = {
        accessToken: accessToken,
        idToken: idToken,
        refreshToken: refToken
      };

      this.store.dispatch(fromStore.StoreRefTokens(referralData));
    }

    return this.token$.pipe(
      switchMap((token) => {
        if (this.refreshToken) {
          const helper = new JwtHelperService();
          if (token === null || helper.isTokenExpired(token)) {
            if (
              this.refreshToken ||
              !helper.isTokenExpired(this.refreshToken)
            ) {
              this.store.dispatch(
                fromStore.ValidateRefreshToken(this.refreshToken)
              );
            } else {
              if (state.url !== '/login') {
                this.router.navigateByUrl('/');
              }
            }
            return [false];
          }
        }
        return [true];
      }),
      first()
    );
  }
}
