<app-header></app-header>
<div class="sign-up-form-container">

  <div class="signup-form max-width">

    <p class="caption">CREATE AN ACCOUNT</p>

  <form [formGroup]="signupFormGroup" (keydown)="submitOnEnter($event)">
      <h2>Hello. Register for your account.</h2>

        <mat-form-field [ngClass]="{'valid': signupFormGroup.controls.email.valid}" appearance="outline">
          <mat-label>Email Address</mat-label>
          <input #email matInput formControlName="email">
          <mat-error *ngIf="hasError('email')">{{getErrorMessage('email', ['required', 'email'])}}</mat-error>
        </mat-form-field>

        <mat-form-field [ngClass]="{'valid': signupFormGroup.controls.password.valid}" appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput autocomplete="off" formControlName="password" type="password">
          <mat-error *ngIf="hasError('password')">{{getErrorMessage('password', ['required', 'pattern'])}}</mat-error>
        </mat-form-field>

        <div class="button-container">
          <mat-spinner *ngIf="buttonLoading"  diameter="50"></mat-spinner>
          <button class="army-button army-default" *ngIf="!buttonLoading" (click)="verifyEmail()" [ngClass]="{'army-default-inactive': !signupFormGroup.valid}" type="button">Register</button>
        </div>

      <div class="login-link">
        <hr>
        <a [routerLink]="['/login']" class="army-button army-text login">I have an account</a>
      </div>
    </form>
  </div>

</div>
