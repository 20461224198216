<div *ngIf="achievement" class="dashboard-card" [ngStyle]="{ 'background': 'url(' + achievement?.backgroundImageURL + ')' }">
  <div class="achievements">
      <img [src]="achievement?.iconImageURL" alt="Logo" class="logo">
    <h6>{{ achievement?.title }}</h6>
      <mat-progress-bar mode="determinate" [value]="getProgressPercent()"></mat-progress-bar>
  </div>
</div>

<div *ngIf="!achievement" class="dashboard-card empty">
  <div class="achievements"></div>
</div>
