import { Component, OnInit, Input } from '@angular/core';
import { Leaderboard } from '../../models/dashboard.model';
import { LeaderboardState } from '../../store/reducers/leaderboard.reducer';

@Component({
  selector: 'app-leaderboard-card',
  templateUrl: './leaderboard-card.component.html',
  styleUrls: ['./leaderboard-card.component.scss']
})
export class LeaderboardCardComponent implements OnInit {
  @Input()
  crowsLeaderboard: any;

  @Input()
  assets: any;

  @Input()
  username: any;

  notPlayed: boolean;
  leaderboardAsset: any;
  currentRank: number;

  ngOnInit(): void {
    if (this.assets) {
      this.leaderboardAsset = this.assets.find((asset) => {
        return asset.name === 'CROWS Experience at NMUSA';
      });
    }
    if (this.crowsLeaderboard) {
      this.crowsLeaderboard.leaderboards.players.forEach((entry) => {
        if (entry.playerId === this.username) {
          this.currentRank = entry.rank;
        }
      });
      if (!this.currentRank) {
        this.notPlayed = true;
      }
    }
  }
}
