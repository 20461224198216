<div class="login-form-container">
  <div class="login-form max-width">
    <img src="./assets/icons/VIP-Vert-Negative.svg" alt="VIP Icon" class="vip-icon">

    <h5>Forgot Password</h5>

    <form [formGroup]="resetForm" (keydown)="submitOnEnter($event)">
      <mat-form-field [ngClass]="{'valid': resetForm.controls.email.valid}" appearance="outline">
        <mat-label>Email Address</mat-label>
        <input formControlName="email" matInput>
        <mat-error *ngIf="hasError('email')">{{ getErrorMessage('email', ['email', 'required']) }}</mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="{'valid': resetForm.controls.confirmation.valid}" appearance="outline">
        <mat-label>Confirmation Code</mat-label>
        <input formControlName="confirmation" matInput>
        <mat-error *ngIf="hasError('confirmation')">{{ getErrorMessage('confirmation', ['required']) }}</mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="{'valid': resetForm.controls.newPassword.valid && resetForm.value.newPassword}" appearance="outline">
        <mat-label>New Password</mat-label>
        <input matInput type="password" autocomplete="off" formControlName="newPassword">
        <mat-error *ngIf="hasError('newPassword')">{{ getErrorMessage('newPassword', ['required', 'pattern']) }}</mat-error>
      </mat-form-field>
    </form>

    <div class="spinner" *ngIf="buttonLoading">
      <mat-spinner diameter="48"></mat-spinner>
    </div>
    <button *ngIf="!buttonLoading" [ngClass]="{'army-default-inactive': !isValid()}" class="army-button army-default" (click)="submit()">Reset Password</button>
  </div>
</div>
