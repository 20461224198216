import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CrowsLeaderboard } from '../models/leaderboards.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeaderboardService {
  constructor(private http: HttpClient) {}

  getCrowsLeaderboard(accountId: string): Observable<CrowsLeaderboard> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.post<CrowsLeaderboard>(
      '/vip/gameplay/CROWs/leaderboards',
      { accountId },
      { headers }
    );
  }
}
