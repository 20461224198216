import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  LoginResponse,
  SettingsResponse,
  SignUpData
} from '../models/auth.models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) {}

  /**
   * Log in. This uses the email and password as input for the
   * http request body.
   */
  login(data: { email: string; password: string }): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.post<any>('/aau/account/VIP/login', JSON.stringify(data), {
      headers
    });
  }

  /**
   * Logs the user out via Cognito.
   */
  logout(token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.get<any>('/aau/account/VIP/logout', { headers });
  }

  verifyEmail(data: { email: string; password: string }): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('RegistrationComponent', 'CROWS');

    return this.http.post<any>(
      '/aau/account/VIP/register',
      JSON.stringify(data),
      { headers }
    );
  }

  /**
   * Log in. This uses the email and password as input for the
   * http request body.
   */
  signup(data: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    const sendData = {
      email: data.email,
      confirmationCode: data.confirmationCode,
      firstName: data.firstName,
      lastName: data.lastName,
      username: data.username,
      zip: data.zip,
      dob: data.dob
    };

    return this.http.post<any>(
      '/aau/account/VIP/confirmRegistration',
      JSON.stringify(sendData),
      { headers }
    );
  }

  delayedSignup(data: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    const sendData = {
      email: data.email,
      confirmationCode: data.confirmationCode,
      firstName: data.firstName,
      lastName: data.lastName,
      vipName: data.vipName,
      zipCode: data.zipCode,
      dateOfBirth: data.dateOfBirth,
      password: data.password
    };

    return this.http.post<any>(
      '/test/delayedRegister',
      JSON.stringify(sendData),
      { headers }
    );
  }

  checkEmail(email: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.post<any>(
      '/aau/account/VIP/checkEmail',
      JSON.stringify({ email }),
      { headers }
    );
  }

  checkUsername(username: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.post<any>(
      '/aau/account/VIP/checkUsername',
      JSON.stringify({ username }),
      { headers }
    );
  }

  /**
   * Change Username
   */
  changeUsername(idToken: string, data: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', idToken)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.post<any>(
      '/aau/account/VIP/updateAccount',
      JSON.stringify({ username: data.username }),
      { headers }
    );
  }

  /**
   * Change Email
   */
  changeEmail(
    currentEmail: string,
    data: any,
    idToken: string
  ): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', idToken)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    const dataObj = { newEmail: data.emailAddress, currentEmail };

    return this.http.post<any>(
      '/aau/account/VIP/changeEmail/init',
      JSON.stringify(dataObj),
      { headers }
    );
  }

  /**
   * Confirm Change Email
   */
  confirmChangeEmail(confirmationCode: any, idToken: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', idToken)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.post<any>(
      '/aau/account/VIP/changeEmail/confirm',
      JSON.stringify({ confirmationCode }),
      { headers }
    );
  }

  /**
   * Change Password
   */
  changePassword(
    accessToken: string,
    idToken: string,
    data: any
  ): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', idToken)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    const dataObj = {
      accessToken,
      currentPassword: data.oldPassword,
      newPassword: data.newPassword
    };

    return this.http.post<any>(
      '/aau/account/VIP/changePassword',
      JSON.stringify(dataObj),
      { headers }
    );
  }

  /**
   * Reset password init.
   */
  resetPasswordInit(emailAddress): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.post<any>(
      '/aau/account/VIP/resetPassword/init',
      JSON.stringify({ emailAddress: emailAddress.email }),
      { headers }
    );
  }

  /**
   * Reset password init.
   */
  resetPasswordComplete(data: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    const resetData = {
      emailAddress: data.email,
      confirmationCode: data.confirmation,
      password: data.newPassword
    };

    return this.http.post<any>(
      '/aau/account/VIP/resetPassword/confirm',
      JSON.stringify(resetData),
      { headers }
    );
  }

  /**
   * Token refresh.
   */
  validateRefreshToken(refreshToken: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.post<any>(
      '/aau/account/VIP/refreshToken',
      JSON.stringify({ refreshToken }),
      { headers }
    );
  }

  /**
   * Delete user account.
   */
  deleteUserAccount(token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.post<any>(
      '/aau/account/VIP/deleteUserAccount',
      {},
      {
        headers
      }
    );
  }

  /**
   * Export user data.
   */
  exportUserData(token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.post<any>(
      '/vip/user/VIP/exportUserData',
      {},
      {
        headers
      }
    );
  }

  /**
   * Cancel change email request
   */
  cancelChangeEmail(accountId: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.post<any>(
      '/aau/account/VIP/changeEmail/cancel',
      JSON.stringify({ accountId }),
      { headers }
    );
  }

  /**
   * Resend Confirmation Code
   */
  resendConfirmationCode(email: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.post<any>(
      '/aau/account/VIP/resendConfirmationCode',
      JSON.stringify({ email }),
      {
        headers
      }
    );
  }
}
