import { AuthService } from '../services/auth.service';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

export class UsernameValidator {
  static createValidator(authService: AuthService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return authService.checkUsername(control.value).pipe(
        map((result: any) => (!result ? { webPurify: true } : null)),
        catchError((err) => {
          if (err.error.errorType === 'InvalidUsernameException') {
            return of({ webPurify: true });
          }
          if (err.error.errorType === 'UsernameTooLongException') {
            return of({ tooLong: true });
          }
          if (err) {
            return of({ unknown: true });
          }
        })
      );
    };
  }
}
