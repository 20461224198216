<div class="delete-modal">
  <h6 mat-dialog-title>Delete your account</h6>
  <div mat-dialog-content>
    <p class="body">Are you sure you want to continue? This process cannot be undone.</p>
  </div>
  <div mat-dialog-actions>
    <button class="mobile-secondary medium" (click)='submit(true)'>Confirm Delete</button>
    <button class="mobile-secondary medium-flat" (click)='submit(false)'>Cancel</button>
  </div>
</div>
