<div class="container">
  <div class="objective-container">
    <div class="objective">
      <div class="objective-icons">
        <img src="./assets/icons/objective-icon-bg.svg" alt="Objective Icon Background" class="bg">
        <img [src]="currentObjective?.iconImageURL" alt="Objective Icon" class="icon">
      </div>

      <div class="objective-text">
        <h5>{{ currentObjective?.name }}</h5>
        <p class="body">{{ objectiveCompleted ? currentObjective?.completedDescription : currentObjective?.notCompletedDescription }}</p>
      </div>

    </div>

    <div class="career-nav" *ngIf="currentObjective?.type === 'scannable'">
      <button class="mobile-secondary small"><img src="./assets/icons/info.svg" alt="info" class="info-icon">Requires Career Navigator</button>
    </div>

    <img src="./assets/icons/completed-check-green.svg" alt="Completed Checkmark" class="completed" *ngIf="objectiveCompleted">

  </div>
</div>
