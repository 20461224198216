<app-header class="slide-in" *ngIf='headerOnPage() && url !== "/dashboard"'></app-header>
<div id="top-of-page" class="outlet" [@routeAnimations]="checkIsSafari() ? null : getAnimationData(routerOutlet)" (@routeAnimations.done)="animEnd()">
    <router-outlet #routerOutlet="outlet"></router-outlet>
</div>

<div class="qr-code-holder" *ngIf="(loggedIn$ | async) && qrCodeOnPage() && !userDetailsLoading" (click)="toggleQrCode()" [class.isShowing]="isQrCode" [class.notShowing]="qrToggled" [class.firstShow]="firstShow" [class.isLandscape]="isLandscape">
  <div class="qr-code-trigger" [class.notShowingTrigger]="qrToggled">
    <img src="/assets/icons/qr-icon.svg" alt="QR Code Icon">
    <p>{{ userDetails?.userInfo?.username }}</p>
    <img src="/assets/icons/slide-up.svg" alt="Open QR Code" [class.closeArrowAnimate]="arrowAnimate" [class.firstShowTrigger]="firstShow">
  </div>

  <div class="qr-code-inner" [class.isLandscape]="isLandscape">
    <img src="/assets/icons/vip-icon.svg" alt="VIP Logo" class="qr-logo" [class.isLandscape]="isLandscape">

    <p class="qr-user">{{ userDetails?.userInfo?.username }}</p>

    <img [src]="userDetails?.userInfo?.qrCodeImageURL" alt="Placeholder" class="qr-code" [class.isLandscape]="isLandscape">

    <p class="qr-title"  [class.isLandscape]="isLandscape">Virtual Interactive Pass</p>

    <img src="/assets/icons/slide-up.svg" alt="Close QR Code" class="close-arrow">
  </div>
</div>

<div class="qr-code-overlay" (click)="toggleQrCode()" [class.isShowing]="isQrCode"></div>
