import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginService {
  password: string;
  email: string;

  setUserInfo(info): void {
    if (info.password) {
      this.password = info.password;
    }
    if (info.email) {
      this.email = info.email;
    }
  }

  getPassword(): string {
    const password = this.password;
    this.password = '';
    return password;
  }

  getEmail(): string {
    const email = this.email;
    this.email = '';
    return email;
  }
}
