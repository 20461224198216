import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../store';
import * as fromStore from '../../store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {
  isLoggedIn$: Observable<any>;
  isLoggedIn: boolean;

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {
    this.isLoggedIn$ = this.store.pipe(select(fromStore.isLoggedIn));
  }

  ngOnInit(): void {
    if (this.isLoggedIn$) {
      this.isLoggedIn$.subscribe((loggedIn) => {
        this.isLoggedIn = loggedIn;
      });
    }
  }

  goHome() {
    if (this.isLoggedIn) {
      this.store.dispatch(fromStore.GetUserDetails());
      this.store.dispatch(fromStore.GetCrowsLeaderboard());
    }
    this.router.navigateByUrl('/');
  }
}
