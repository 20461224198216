import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { UsernameValidator } from '../../validators/username-validator';

@Component({
  selector: 'app-delayed-sign-up-form',
  templateUrl: './delayed-sign-up-form.component.html',
  styleUrls: ['./delayed-sign-up-form.component.scss']
})
export class DelayedSignUpFormComponent implements OnInit {
  @Input()
  email: string;

  @Input()
  buttonLoading: boolean;

  @Input()
  assets: any;

  @Output()
  signupData = new EventEmitter<any>();

  @Output()
  resendConfirmationCode = new EventEmitter<any>();

  delayedSignupFormGroup: FormGroup;

  checkboxChecked = false;

  currentYear = formatDate(new Date(), 'yyyy', 'en');
  startDate = new Date(Number(this.currentYear) - 10, 0, 1);

  validationMessages = {
    firstName: {
      required: 'First name is required.',
      pattern: 'First name is invalid.'
    },
    lastName: {
      required: 'Last name is required.',
      pattern: 'Last name is invalid.'
    },
    vipName: {
      required: 'VIP name is required.',
      pattern: 'VIP name is invalid.',
      webPurify: 'Username can not contain profanity.',
      tooLong: 'Username must be less than 15 characters.',
      unknown: 'Unknown error. Please try again.'
    },
    confirmationCode: {
      required: 'Confirmation code is required.',
      pattern: 'Confirmation code is invalid.'
    },
    email: {
      required: 'Email is required.',
      pattern: 'Email is invalid.'
    },
    dateOfBirth: {
      required: 'Date of birth is required.'
    },
    zipCode: {
      required: 'Zip code is required.',
      pattern: 'Zip code is invalid.'
    },
    password: {
      required: 'Password is required.',
      pattern:
        'Your password must be at least 8 characters. It must include upper and lowercase letters and at least 1 number.'
    }
  };

  constructor(
    private readonly formBuilder: FormBuilder,
    private authService: AuthService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.delayedSignupFormGroup = this.formBuilder.group({
      email: ['', [Validators.required]],
      firstName: ['', [Validators.required, Validators.pattern(/^\S*$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^\S*$/)]],
      vipName: [
        '',
        [Validators.required, Validators.pattern(/^\S*$/)],
        UsernameValidator.createValidator(this.authService)
      ],
      dateOfBirth: ['', [Validators.required]],
      zipCode: [
        '',
        [Validators.required, Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)]
      ],
      formAgreement: [''],
      confirmationCode: [
        '',
        [Validators.required, Validators.pattern(/^[0-9]*$/)]
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$'),
          Validators.pattern(
            '^[A-Za-z0-9!@#$%^&*()\\-`.+,/"]+(?: +[A-Za-z0-9!@#$%^&*()\\-`.+,/"]+)*$'
          )
        ]
      ]
    });
    this.delayedSignupFormGroup.patchValue({ email: this.email });
  }

  toggleChecked() {
    this.checkboxChecked = !this.checkboxChecked;
  }

  hasError(key: string, group?: string): boolean {
    const field = group ? `${group}.${key}` : key;
    const control = this.delayedSignupFormGroup.get(field);

    if (!control) {
      return false;
    }

    if ((!control.valid && control.touched) || control.untouched) {
      return control.errors ? true : false;
    }
  }

  getErrorMessage(key: string, types: Array<string>, group?: string): any {
    const field = group ? `${group}.${key}` : key;
    const control = this.delayedSignupFormGroup.get(field);

    if (this.hasError(key, group)) {
      for (const errorType of types) {
        if (control.errors[errorType]) {
          return this.validationMessages[key][errorType];
        }
      }
    }
  }

  formatDateOfBirth(dateOfBirth): string {
    const date = formatDate(dateOfBirth, 'yyyy-MM-dd', 'en-US');
    return date;
  }

  submit(): void {
    if (this.delayedSignupFormGroup.invalid) {
      return;
    }
    const form = this.delayedSignupFormGroup.value;
    this.signupData.emit(form);
  }

  resendCode() {
    this.resendConfirmationCode.emit();
  }
}
