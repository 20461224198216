<header>

  <!-- Dashboard header -->

  <div class="header-main" *ngIf='url === "/dashboard"'>
    <div class="header-container">
      <img src="./assets/icons/VIP-Logo-Dark.svg" alt="VIP Gold Logo" class="vip-logo-gold">
      <p (click)="toggleQR()" class="qr-code">Your Pass</p>
      <p [routerLink]="['/settings']">Profile</p>
    </div>
  </div>

  <!-- Standard header -->

  <div class="header-alt" *ngIf='url !== "/dashboard" && url !== "/signup" && url !=="/join"'>
  <div class="header-container">
    <button (click)="goBack()" class="army-arrow army-arrow"><img src="./assets/icons/arrow_back.svg" class="material-icons right small-icon"></button>
    <img src="./assets/icons/VIP-Logo-Dark.svg" alt="VIP Gold Logo" class="vip-logo-gold">
    <p *ngIf="url !== '/update-password' && url !== '/update-info' && !url.includes('settings')" (click)="toggleQR()" class="qr-code">Your Pass</p>
    <p [ngClass]="{'hide-profile': (url === '/update-password' || url === '/update-info' || url.includes('settings'))}" [routerLink]="['/settings']">Profile</p>
  </div>
  </div>

  <!-- Signup header -->

  <div class="header-signup" *ngIf='url === "/signup" || url === "/signup-confirm"'>
    <div class="header-container">
      <button (click)="goBack()" class="army-arrow army-arrow"><img src="./assets/icons/arrow_back.svg" class="material-icons right small-icon"></button>
      <img src="./assets/icons/VIP-Logo-Dark.svg" alt="VIP Gold Logo" class="vip-logo-gold">
      <p class="caption"></p>
    </div>
  </div>

    <!-- Delayed Signup header -->

    <div class="header-signup delayed" *ngIf='url === "/join"'>
      <div class="header-container">
        <img src="./assets/icons/VIP-Logo-Dark.svg" alt="VIP Gold Logo" class="vip-logo-gold">
      </div>
    </div>
  </header>
  