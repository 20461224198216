import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { LeaderboardOverview, Leaderboard } from '../../models/dashboard.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-leaderboard-detail',
  templateUrl: './leaderboard-detail.component.html',
  styleUrls: ['./leaderboard-detail.component.scss']
})
export class LeaderboardDetailComponent implements OnChanges {
  @Input()
  leaderboards: any;

  @Input()
  assets: any;

  @Input()
  username: string;

  currentLeaderboard: any;
  leaderboardId: string;
  topThree = [];
  relativeFive = [];
  notPlayed: boolean;

  constructor(private activatedRoute: ActivatedRoute) {
    this.leaderboardId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnChanges(): void {
    switch (this.leaderboardId) {
      case 'CROWS': {
        const asset = this.assets.find((currentAsset) => {
          if (currentAsset.leaderboard) {
            return currentAsset.leaderboard.identifier === 'CROWS';
          }
        });
        if (!this.leaderboards.crowsLeaderBoard.leaderboards.currentRank) {
          this.notPlayed = true;
        }
        this.currentLeaderboard = {
          ...this.leaderboards.crowsLeaderBoard,
          ...asset
        };
      }
    }
    this.currentLeaderboard.leaderboards.players.forEach((player) => {
      const { currentRank } = this.currentLeaderboard.leaderboards;
      if (player.rank <= 3) {
        this.topThree.push(player);

        // Push 7 if in top 10
      } else if (currentRank <= 10 && player.rank > 3) {
        this.relativeFive.push(player);

        // Push 5 if not in top 10
      } else if (
        this.currentLeaderboard.leaderboards.players.indexOf(player) >= 5 &&
        !this.notPlayed
      ) {
        this.relativeFive.push(player);

        // Push 7 if not played
      } else if (this.notPlayed) {
        this.relativeFive.push(player);
      }
    });
  }
}
