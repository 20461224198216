<div class="login-form-container">
    <div class="login-form max-width">
      <img src="./assets/icons/VIP-Vert-Negative.svg" alt="VIP Icon" class="vip-icon">

      <h5>Forgot Password</h5>

      <form [formGroup]="loginForm" (keydown)="submitOnEnter($event)">
        <p class="body-tiny">Enter the email address associated with your account. We'll send you a code to reset your password.</p>
        <mat-form-field [ngClass]="{'valid': loginForm.controls.email.valid}" appearance="outline">
          <mat-label>Email Address</mat-label>
          <input formControlName="email" matInput>
          <mat-error *ngIf="hasError('email')">{{ getErrorMessage('email', ['email', 'required']) }}</mat-error>
        </mat-form-field>
      </form>

      <div class="spinner" *ngIf="buttonLoading">
        <mat-spinner diameter="48"></mat-spinner>
      </div>
      <button *ngIf="!buttonLoading" [ngClass]="{'army-default-inactive': !isValid()}" class="army-button army-default" (click)="submit()">Get Code</button>
      <div class="login-footer">
        <button [routerLink]="['/login']" class="army-button army-secondary sign-up">Cancel</button>
        <a href="https://www.goarmy.com/?iom=AFOD-2675107_VIP_Website" target="_blank" class="goarmy-link"><img src="./assets/icons/army_logo1_rev_rgb.svg" alt="US Army Logo" class="army-logo"></a>
      </div>
    </div>
  </div>
