import * as fromActions from '../actions/userDetails.actions';
import * as fromAuth from '../actions/auth.actions';
import { UserInfo } from '../../models/userDetails.model';
import { Action, createReducer, on } from '@ngrx/store';

export interface UserDetailsState {
  userInfo: UserInfo;
  notifications: any[];
  activities: any[];
  interactions: any[];
  userDetailsLoading: boolean;
}

export const initialState: UserDetailsState = {
  userInfo: null,
  notifications: null,
  activities: null,
  interactions: null,
  userDetailsLoading: false
};

export const userDetailsReducer = createReducer(
  initialState,
  on(fromActions.GetUserDetails, (state, action) => {
    return {
      ...state,
      userDetailsLoading: true
    };
  }),
  on(fromActions.GetUserDetailsSuccess, (state, action) => {
    return {
      ...state,
      userInfo: action.payload.userInfo,
      notifications: action.payload.notifications,
      activities: action.payload.activities,
      interactions: action.payload.interactions,
      userDetailsLoading: false
    };
  }),
  on(fromActions.GetUserDetailsFailure, (state, action) => {
    return initialState;
  }),
  on(fromAuth.LogoutSuccess, (state, action) => {
    return initialState;
  })
);

export function reducer(
  state: UserDetailsState | undefined,
  action: Action
): UserDetailsState {
  return userDetailsReducer(state, action);
}

export const getUserInfo = (state: UserDetailsState) =>
  state ? state.userInfo : null;

export const getNotifications = (state: UserDetailsState) =>
  state ? state.notifications : null;

export const getActivities = (state: UserDetailsState) =>
  state ? state.activities : null;

export const getInteractions = (state: UserDetailsState) =>
  state ? state.interactions : null;

export const userDetailsAreLoading = (state: UserDetailsState) =>
  state && state.userDetailsLoading ? state.userDetailsLoading : false;
