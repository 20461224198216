import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Achievement } from '../../models/dashboard.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-objective-detail',
  templateUrl: './objective-detail.component.html',
  styleUrls: ['./objective-detail.component.scss']
})
export class ObjectiveDetailComponent implements OnChanges {
  @Input()
  interactions: any;
  @Input()
  objectives: any;

  objectiveId: string;
  currentObjective: any;
  objectiveCompleted: boolean;

  constructor(private activatedRoute: ActivatedRoute) {
    this.objectiveId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnChanges(): void {
    this.currentObjective = this.objectives.find((objective) => {
      return objective.identifier === this.objectiveId;
    });
    this.objectiveCompleted = this.interactions.find((interaction) => {
      return this.currentObjective.identifier === interaction.objective;
    });
  }
}
