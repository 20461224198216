import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  url: string;
  @Input() currentIndex: number;
  @Output() newIndex = new EventEmitter();
  previousIndex: number;

  constructor(
    private router: Router,
    private location: Location,
    private app: AppComponent
  ) {}

  ngOnInit(): void {
    this.url = this.router.url;
  }

  goBack() {
    const url = this.url.toLowerCase();
    if (
      url.includes('idtoken') &&
      url.includes('refreshtoken') &&
      url.includes('accesstoken')
    ) {
      this.router.navigateByUrl('/dashboard');
    } else {
      this.location.back();
    }
  }

  changeIndex() {
    this.previousIndex = this.currentIndex - 2;
    this.currentIndex = this.currentIndex - 1;
    this.newIndex.emit(this.previousIndex);
  }

  toggleQR() {
    this.app.toggleQrCode();
  }
}
