<div class="achievements-container">
  <div class="achievements">
    <div class="achievement-header">
      <h2>Achievements</h2>
    <button class="mobile-secondary small">{{ completedAchievements?.length }} of {{ achievements?.length }} Achievements Earned</button>
    </div>
    <div class="achievement-cards">
      <div *ngFor="let achievement of achievements" [ngStyle]="{ 'background': 'url(' + achievement?.backgroundImageURL + ')' }" class="achievement-card" [routerLink]="['/achievement-detail/' + achievement.identifier]">
        <div class="achievement-card-header">
        <h6>{{ achievement.title }}</h6>
        <img *ngIf="(getAssetNumberCompleted(achievement) >= achievement?.objectives?.length)" class="checkbox" src="./assets/icons/completed-check.svg" alt="Complete Checkbox">
        <img *ngIf="!(getAssetNumberCompleted(achievement) >= achievement?.objectives?.length)" class="checkbox" src="./assets/icons/incompleted-box.svg" alt="Incomplete Checkbox">
        </div>

        <div class="achievement-card-footer">
          <div class="position">
          <h5>{{ getAssetNumberCompleted(achievement) }}/{{ achievement?.objectives?.length | number }}</h5>
            <p *ngIf="!(getAssetNumberCompleted(achievement) >= achievement?.achievements?.length)" class="caption">Objectives Earned</p>
            <p *ngIf="(getAssetNumberCompleted(achievement) >= achievement?.achievements?.length)" class="caption">Objectives Earned</p>
          </div>
          <div class="icon">
            <img [src]="achievement.iconImageURL" alt="Logo" class="logo">
          </div>
        </div>
      </div>
      <div *ngFor="let i of [].constructor()" class="achievement-card empty-bg">
      </div>
    </div>
  </div>
</div>

