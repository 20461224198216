import { Injectable } from '@angular/core';

import { Action, Store, select } from '@ngrx/store';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { map, exhaustMap, catchError, withLatestFrom } from 'rxjs/operators';

import * as leaderboardActions from '../actions/leaderboard.actions';
import { LeaderboardService } from '../../services/leaderboard.service';
import * as fromSelectors from '../selectors';

@Injectable()
export class LeaderboardEffects {
  constructor(
    private actions$: Actions,
    private leaderboardService: LeaderboardService,
    private store$: Store
  ) {}

  getCrowsLeaderboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(leaderboardActions.GetCrowsLeaderboard)).pipe(
      withLatestFrom(this.store$.pipe(select(fromSelectors.getAccountId))),
      exhaustMap(([action, accountId]) =>
        this.leaderboardService.getCrowsLeaderboard(accountId).pipe(
          map((res: any) => leaderboardActions.GetCrowsLeaderboardSuccess(res)),
          catchError((err) =>
            of(leaderboardActions.GetCrowsLeaderboardFailure(err))
          )
        )
      )
    )
  );
}
