import { createAction } from '@ngrx/store';
export const Login = createAction('[AUTH] Login', (payload = null) => ({
  payload
}));
export const LoginSuccess = createAction(
  '[AUTH] Login Success',
  (payload = null) => ({ payload })
);
export const LoginFailure = createAction(
  '[AUTH] Login Failure',
  (payload = null) => ({ payload })
);
export const ResetLogin = createAction(
  '[AUTH] Reset Login',
  (payload = null) => ({ payload })
);
export const VerifyEmail = createAction(
  '[AUTH] Verify Email',
  (payload = null) => ({ payload })
);
export const VerifyEmailSuccess = createAction(
  '[AUTH] Verify Email Success',
  (payload = null) => ({ payload })
);
export const VerifyEmailFailure = createAction(
  '[AUTH] Verify Email Failure',
  (payload = null) => ({ payload })
);
export const Signup = createAction('[AUTH] Signup', (payload = null) => ({
  payload
}));
export const SignupSuccess = createAction(
  '[AUTH] Signup Success',
  (payload = null) => ({ payload })
);
export const SignupFailure = createAction(
  '[AUTH] Signup Failure',
  (payload = null) => ({ payload })
);
export const AutoLoginSignupSuccess = createAction(
  '[AUTH] Auto Login Signup Success',
  (payload = null) => ({ payload })
);
export const DelayedSignup = createAction(
  '[AUTH] Delayed Signup',
  (payload = null) => ({ payload })
);
export const DelayedSignupSuccess = createAction(
  '[AUTH] Delayed Signup Success',
  (payload = null) => ({ payload })
);
export const DelayedSignupFailure = createAction(
  '[AUTH] Delayed Signup Failure',
  (payload = null) => ({ payload })
);
export const FirstLogin = createAction(
  '[AUTH] Update First Login',
  (payload = null) => ({ payload })
);
export const Logout = createAction('[AUTH] Logout', (payload = null) => ({
  payload
}));
export const LogoutSuccess = createAction(
  '[AUTH] Logout Success',
  (payload = null) => ({ payload })
);
export const LogoutFailure = createAction(
  '[AUTH] Logout Failure',
  (payload = null) => ({ payload })
);
export const ChangePassword = createAction(
  '[AUTH] Change Password',
  (payload = null) => ({ payload })
);
export const ChangePasswordSuccess = createAction(
  '[AUTH] Change Password Success',
  (payload = null) => ({ payload })
);
export const ChangePasswordFailure = createAction(
  '[AUTH] Change Password Failure',
  (payload = null) => ({ payload })
);
export const ChangeEmail = createAction(
  '[AUTH] Change Email',
  (payload = null) => ({ payload })
);
export const ChangeEmailSuccess = createAction(
  '[AUTH] Change Email Success',
  (payload = null) => ({ payload })
);
export const ChangeEmailFailure = createAction(
  '[AUTH] Change Email Failure',
  (payload = null) => ({ payload })
);
export const ChangeUsername = createAction(
  '[AUTH] Change Username',
  (payload = null) => ({ payload })
);
export const ChangeUsernameSuccess = createAction(
  '[AUTH] Change Username Success',
  (payload = null) => ({ payload })
);
export const ChangeUsernameFailure = createAction(
  '[AUTH] Change Username Failure',
  (payload = null) => ({ payload })
);
export const ResetPasswordInit = createAction(
  '[AUTH] Reset Password Init',
  (payload = null) => ({ payload })
);
export const ResetPasswordInitSuccess = createAction(
  '[AUTH] Reset Password Init Success',
  (payload = null) => ({ payload })
);
export const ResetPasswordInitFailure = createAction(
  '[AUTH] Reset Password Init Failure',
  (payload = null) => ({ payload })
);
export const ResetPasswordComplete = createAction(
  '[AUTH] Reset Password Complete',
  (payload = null) => ({ payload })
);
export const ResetPasswordCompleteSuccess = createAction(
  '[AUTH] Reset Password Complete Success',
  (payload = null) => ({ payload })
);
export const ResetPasswordCompleteFailure = createAction(
  '[AUTH] Reset Password Complete Failure',
  (payload = null) => ({ payload })
);
export const ValidateRefreshToken = createAction(
  '[AUTH] Validate Refresh Token',
  (payload = null) => ({ payload })
);
export const ValidateRefreshTokenSuccess = createAction(
  '[AUTH] Validate Refresh Token Success',
  (payload = null) => ({ payload })
);
export const ValidateRefreshTokenFailure = createAction(
  '[AUTH] Validate Refresh Token Failure',
  (payload = null) => ({ payload })
);
export const DeleteUserAccount = createAction(
  '[AUTH] Delete User Account',
  (payload = null) => ({ payload })
);
export const DeleteUserAccountSuccess = createAction(
  '[AUTH] Delete User Account Success',
  (payload = null) => ({ payload })
);
export const DeleteUserAccountFailure = createAction(
  '[AUTH] Delete User Account Failure',
  (payload = null) => ({ payload })
);
export const ExportUserData = createAction(
  '[AUTH] Export User Data',
  (payload = null) => ({ payload })
);
export const ExportUserDataSuccess = createAction(
  '[AUTH] Export User Data Success',
  (payload = null) => ({ payload })
);
export const ExportUserDataFailure = createAction(
  '[AUTH] Export User Data Failure',
  (payload = null) => ({ payload })
);
export const CancelChangeEmail = createAction(
  '[AUTH] Cancel Change Email',
  (payload = null) => ({ payload })
);
export const CancelChangeEmailSuccess = createAction(
  '[AUTH] Cancel Change Email Success',
  (payload = null) => ({ payload })
);
export const CancelChangeEmailFailure = createAction(
  '[AUTH] Cancel Change Email Failure',
  (payload = null) => ({ payload })
);
export const CancelChangeEmailErrorsReset = createAction(
  '[AUTH] Cancel Change Email Errors Reset',
  (payload = null) => ({ payload })
);
export const ResendConfirmationCode = createAction(
  '[AUTH] Resend Confirmation Code',
  (payload = null) => ({ payload })
);
export const ResendConfirmationCodeSuccess = createAction(
  '[AUTH] Resend Confirmation Code Success',
  (payload = null) => ({ payload })
);
export const ResendConfirmationCodeFailure = createAction(
  '[AUTH] Resend Confirmation Code Failure',
  (payload = null) => ({ payload })
);
export const ConfirmChangeEmail = createAction(
  '[AUTH] Confirm Change Email',
  (payload = null) => ({ payload })
);
export const ConfirmChangeEmailSuccess = createAction(
  '[AUTH] Confirm Change Email Success',
  (payload = null) => ({ payload })
);
export const ConfirmChangeEmailFailure = createAction(
  '[AUTH] Confirm Change Email Failure',
  (payload = null) => ({ payload })
);
export const RegisterButtonReset = createAction(
  '[AUTH] Register Button Reset',
  (payload = null) => ({ payload })
);
export const StoreRefTokens = createAction(
  '[AUTH] Store Referral Tokens',
  (payload = null) => ({ payload })
);
