import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as CustomValidators from '../../validators';
import { AutoLoginService } from '../../services/auto-login.service';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent implements OnInit {
  @Input()
  buttonLoading;

  @Output()
  resetPasswordComplete = new EventEmitter();

  resetForm: FormGroup;

  validationMessages = {
    email: {
      email: 'Email address is invalid.',
      required: 'Email address is required.'
    },
    confirmation: {
      required: 'The confirmation code is required.'
    },
    newPassword: {
      required: 'Password is required.',
      pattern:
        'Your password must be at least 8 characters. It must include upper and lowercase letters and at least 1 number.'
    }
  };

  constructor(
    private readonly formBuilder: FormBuilder,
    private autologinService: AutoLoginService
  ) {}

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      confirmation: ['', Validators.required],
      newPassword: [
        '',
        [
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$'),
          Validators.pattern(
            '^[A-Za-z0-9!@#$%^&*()\\-`.+,/"]+(?: +[A-Za-z0-9!@#$%^&*()\\-`.+,/"]+)*$'
          ),
          Validators.required
        ]
      ]
    });
    this.resetForm.patchValue({ email: this.autologinService.getEmail() });
  }

  hasError(key: string): boolean {
    const control = this.resetForm.get(key);

    if (!control) {
      return false;
    }

    if ((!control.valid && control.touched) || control.untouched) {
      return control.errors ? true : false;
    }
  }

  getErrorMessage(key: string, types: Array<string>): any {
    const field = key;
    const control = this.resetForm.get(field);

    if (this.hasError(key)) {
      for (const errorType in types) {
        if (control.errors[types[errorType]]) {
          return this.validationMessages[key][types[errorType]];
        }
      }
    }
  }

  isValid(): boolean {
    return this.resetForm.valid;
  }

  submit(): void {
    const { value, valid } = this.resetForm;

    if (valid) {
      this.resetPasswordComplete.emit(value);
    }
  }

  submitOnEnter(event: { keyCode: number }): void {
    if (event.keyCode === 13) {
      this.submit();
    }
  }
}
