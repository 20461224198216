import { Component, OnInit } from '@angular/core';
import { Routes, Router } from '@angular/router';

@Component({
  selector: 'app-career-nav-top-bar',
  templateUrl: './career-nav-top-bar.component.html',
  styleUrls: ['./career-nav-top-bar.component.scss']
})
export class CareerNavTopBarComponent implements OnInit {
  url: string;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.url = this.router.url;
  }
}
