import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { LayoutModule } from '@angular/cdk/layout';
import { NotificationsModule } from './notifications/notifications.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import { environment } from '../environments/environment';

// Store
import { StoreModule, MetaReducer, ActionReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';
import { EffectsModule } from '@ngrx/effects';
import { reducers, effects } from './store';
import { localStorageSync } from 'ngrx-store-localstorage';

// Guards
import { AuthGuard } from './guards/auth.guard';

// Components
import { LoginContainerComponent } from './containers/login-container/login-container.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { HeaderComponent } from './components/header/header.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { ActivityComponent } from './components/activity/activity.component';
import { AchievementsComponent } from './components/achievements/achievements.component';
import { LeaderboardsComponent } from './components/leaderboards/leaderboards.component';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { NotificationsContainerComponent } from './containers/notifications-container/notifications-container.component';
import { NotificationDetailComponent } from './components/notification-detail/notification-detail.component';
import { SettingsContainerComponent } from './containers/settings-container/settings-container.component';
import { PersonalInfoFormComponent } from './components/personal-info-form/personal-info-form.component';
import { SignUpFormComponent } from './components/sign-up-form/sign-up-form.component';
import { SignUpContainerComponent } from './containers/sign-up-container/sign-up-container.component';
import { FontDemoComponent } from './components/font-demo/font-demo.component';
import { WelcomeFormComponent } from './components/welcome-form/welcome-form.component';
import { CareerNavTopBarComponent } from './components/career-nav-top-bar/career-nav-top-bar.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { SignUpWelcomeComponent } from './components/sign-up-welcome/sign-up-welcome.component';
import { ActivityCardComponent } from './components/activity-card/activity-card.component';
import { AchievementCardComponent } from './components/achievement-card/achievement-card.component';
import { LeaderboardCardComponent } from './components/leaderboard-card/leaderboard-card.component';
import { ActivityDetailComponent } from './components/activity-detail/activity-detail.component';
import { LeaderboardDetailComponent } from './components/leaderboard-detail/leaderboard-detail.component';
import { AchievementDetailComponent } from './components/achievement-detail/achievement-detail.component';
import { FooterComponent } from './components/footer/footer.component';
import { ObjectiveDetailComponent } from './components/objective-detail/objective-detail.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { DelayedSignUpContainerComponent } from './containers/delayed-sign-up-container/delayed-sign-up-container.component';
import { DelayedSignUpFormComponent } from './components/delayed-sign-up-form/delayed-sign-up-form.component';

// Routing
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordFormComponent } from './components/forgot-password-form/forgot-password-form.component';
import { ResetPasswordFormComponent } from './components/reset-password-form/reset-password-form.component';
import { SignUpConfirmFormComponent } from './components/sign-up-confirm-form/sign-up-confirm-form.component';
import { ChangeEmailContainerComponent } from './containers/change-email-container/change-email-container.component';
import { ChangeEmailCancelComponent } from './components/change-email-cancel/change-email-cancel.component';
import { ChangeEmailConfirmComponent } from './components/change-email-confirm/change-email-confirm.component';
import { ScrollService } from './services/scroll.service';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { UpdateInfoComponent } from './components/update-info/update-info.component';

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ['user'],
    rehydrate: true
  })(reducer);
}

export const metaReducers: MetaReducer<any>[] = !environment.production
  ? [storeFreeze, localStorageSyncReducer]
  : [localStorageSyncReducer];

const routes: Routes = [
  {
    path: '',
    component: WelcomeFormComponent,
    data: { animation: 'welcome' }
  },
  {
    path: 'welcome',
    component: WelcomeFormComponent,
    data: { animation: 'welcome' }
  },
  {
    path: 'login',
    component: LoginContainerComponent,
    data: { animation: 'login' }
  },
  {
    path: 'signup',
    component: SignUpContainerComponent,
    data: { animation: 'signup' }
  },
  {
    path: 'signup-confirm',
    component: SignUpContainerComponent,
    data: { animation: 'signup-confirm' }
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    data: { animation: 'dashboard' }
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    component: SettingsContainerComponent,
    data: { animation: 'settings' }
  },
  {
    path: 'leaderboards',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    data: { animation: 'leaderboards' }
  },
  {
    path: 'leaderboard-detail/:id',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    data: { animation: 'leaderboard-detail' }
  },
  {
    path: 'achievements',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    data: { animation: 'achievements' }
  },
  {
    path: 'achievement-detail/:id',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    data: { animation: 'achievement-detail' }
  },
  {
    path: 'activity',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    data: { animation: 'activity' }
  },
  {
    path: 'activity-detail/:id',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    data: { animation: 'activity-detail' }
  },
  {
    path: 'signup-welcome',
    component: SignUpWelcomeComponent,
    data: { animation: 'signup-welcome' }
  },
  {
    path: 'objective-detail/:id',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    data: { animation: 'objective-detail' }
  },
  {
    path: 'update-password',
    canActivate: [AuthGuard],
    component: SettingsContainerComponent,
    data: { animation: 'update-info' }
  },
  {
    path: 'update-info',
    canActivate: [AuthGuard],
    component: SettingsContainerComponent,
    data: { animation: 'update-info' }
  },
  {
    path: 'forgot-password',
    component: LoginContainerComponent
  },
  {
    path: 'reset-password',
    component: LoginContainerComponent
  },
  {
    path: 'account/changeEmail/cancel',
    component: ChangeEmailContainerComponent
  },
  {
    path: 'account/changeEmail/confirm',
    component: ChangeEmailContainerComponent,
    data: { animation: 'email-confirm' }
  },
  // TODO: Remove check for production on prod deploy
  {
    path: 'join',
    component: environment.production
      ? NotFoundPageComponent
      : DelayedSignUpContainerComponent
  },
  {
    path: 'join/:appId',
    component: environment.production
      ? NotFoundPageComponent
      : DelayedSignUpContainerComponent
  },
  {
    path: '404',
    component: NotFoundPageComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginContainerComponent,
    LoginFormComponent,
    HeaderComponent,
    DashboardComponent,
    ActivityComponent,
    AchievementsComponent,
    LeaderboardsComponent,
    NotificationsListComponent,
    NotificationsContainerComponent,
    NotificationDetailComponent,
    SettingsContainerComponent,
    PersonalInfoFormComponent,
    SignUpFormComponent,
    SignUpContainerComponent,
    FontDemoComponent,
    WelcomeFormComponent,
    CareerNavTopBarComponent,
    ActivityCardComponent,
    AchievementCardComponent,
    LeaderboardCardComponent,
    ActivityDetailComponent,
    DeleteModalComponent,
    SignUpWelcomeComponent,
    LeaderboardDetailComponent,
    AchievementDetailComponent,
    FooterComponent,
    ObjectiveDetailComponent,
    ForgotPasswordFormComponent,
    ResetPasswordFormComponent,
    NotFoundPageComponent,
    SignUpConfirmFormComponent,
    ChangeEmailContainerComponent,
    ChangeEmailCancelComponent,
    ChangeEmailConfirmComponent,
    DelayedSignUpContainerComponent,
    DelayedSignUpFormComponent,
    UpdatePasswordComponent,
    UpdateInfoComponent
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    HttpClientModule,
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({ connectInZone: true }),
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule.forRoot(routes, {}),
    MatStepperModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    MatDialogModule,
    NotificationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule
  ],
  providers: [AuthGuard, ScrollService],
  bootstrap: [AppComponent]
})
export class AppModule {}
