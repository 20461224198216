import { Injectable } from '@angular/core';

import { Action, Store, select } from '@ngrx/store';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import {
  map,
  exhaustMap,
  catchError,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import * as fromAuth from '../reducers/index';
import * as fromSelectors from '../selectors';

import * as userDetailsActions from '../actions/userDetails.actions';
import { UserDetailsService } from '../../services/userDetails.service';

@Injectable()
export class UserDetailsEffects {
  constructor(
    private actions$: Actions,
    private userDetailsService: UserDetailsService,
    private store$: Store<fromAuth.AppState>
  ) {}

  getUserDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType(userDetailsActions.GetUserDetails)).pipe(
      withLatestFrom(this.store$.pipe(select(fromSelectors.getIdToken))),
      exhaustMap(([, token]) =>
        this.userDetailsService.getUserDetails(token).pipe(
          map((res: any) => userDetailsActions.GetUserDetailsSuccess(res)),
          catchError((err) => of(userDetailsActions.GetUserDetailsFailure(err)))
        )
      )
    )
  );
}
