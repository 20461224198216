import * as fromActions from '../actions';
import { Action, createReducer, on } from '@ngrx/store';

export interface UserState {
  accessToken: string;
  expiresIn: number;
  tokenType: string;
  refreshToken: string;
  idToken: string;
  email: string;
  userLoading: boolean;
  loggedIn: boolean;
  buttonLoading: boolean;
  firstLogin: boolean;
  accountId: string;
  changeEmailLoading: boolean;
  changeEmailError: string;
  cancelChangeEmailSuccess: boolean;
  showEmailMessage: boolean;
  emailButtonLoading: boolean;
  userButtonLoading: boolean;
}

export const initialState: UserState = {
  accessToken: '',
  expiresIn: null,
  tokenType: '',
  idToken: '',
  refreshToken: '',
  email: '',
  userLoading: false,
  loggedIn: false,
  firstLogin: false,
  accountId: '',
  buttonLoading: false,
  emailButtonLoading: false,
  userButtonLoading: false,
  changeEmailLoading: false,
  changeEmailError: '',
  cancelChangeEmailSuccess: false,
  showEmailMessage: false
};

export const userReducer = createReducer(
  initialState,
  on(fromActions.Login, (state, action) => {
    return {
      ...state,
      email: action.payload.email,
      userLoading: true,
      loggedIn: false
    };
  }),
  on(fromActions.ResetLogin, (state, action) => {
    return {
      ...state,
      userLoading: false,
      buttonLoading: false
    };
  }),
  on(fromActions.ValidateRefreshToken, (state, action) => {
    return {
      ...state,
      email: action.payload.email,
      userLoading: true,
      loggedIn: false
    };
  }),
  on(fromActions.LoginSuccess, (state, action) => {
    return {
      ...state,
      email: action.payload.email,
      tokenType: action.payload.tokenType,
      expiresIn: action.payload.expiresIn,
      accessToken: action.payload.accessToken,
      idToken: action.payload.idToken,
      refreshToken: action.payload.refreshToken,
      userLoading: false,
      loggedIn: true,
      firstLogin: true,
      accountId: action.payload.accountId
    };
  }),
  on(fromActions.StoreRefTokens, (state, action) => {
    return {
      ...state,
      accessToken: action.payload.accessToken,
      idToken: action.payload.idToken,
      refreshToken: action.payload.refreshToken
    };
  }),
  on(fromActions.ValidateRefreshTokenSuccess, (state, action) => {
    return {
      ...state,
      tokenType: action.payload.tokenType,
      expiresIn: action.payload.expiresIn,
      accessToken: action.payload.accessToken,
      idToken: action.payload.idToken,
      refreshToken: action.payload.refreshToken,
      userLoading: false,
      loggedIn: true
    };
  }),
  on(fromActions.ValidateRefreshTokenFailure, (state, action) => {
    return initialState;
  }),
  on(fromActions.GetUserDetailsSuccess, (state, action) => {
    return {
      ...state,
      userInfo: action.payload.userInfo,
      accountId: action.payload.userInfo.userId,
      notifications: action.payload.notifications,
      activities: action.payload.activities,
      interactions: action.payload.interactions,
      userDetailsLoading: false,
      userLoading: false,
      loggedIn: true
    };
  }),
  on(fromActions.VerifyEmail, (state, action) => {
    return {
      ...state,
      email: action.payload.email,
      buttonLoading: true
    };
  }),
  on(fromActions.VerifyEmailSuccess, (state, action) => {
    return {
      ...state,
      buttonLoading: false
    };
  }),
  on(fromActions.VerifyEmailFailure, (state, action) => {
    return {
      ...state,
      buttonLoading: false
    };
  }),
  on(fromActions.Signup, (state, action) => {
    return {
      ...state,
      buttonLoading: true
    };
  }),
  on(fromActions.SignupSuccess, (state, action) => {
    return {
      ...state,
      buttonLoading: false
    };
  }),
  on(fromActions.SignupFailure, (state, action) => {
    return {
      ...state,
      buttonLoading: false
    };
  }),
  on(fromActions.LoginFailure, (state, action) => {
    return {
      ...state,
      tokenType: initialState.tokenType,
      expiresIn: initialState.expiresIn,
      accessToken: initialState.accessToken,
      idToken: initialState.idToken,
      refreshToken: initialState.refreshToken,
      userLoading: initialState.userLoading,
      loggedIn: initialState.loggedIn,
      firstLogin: initialState.firstLogin,
      accountId: initialState.accountId
    };
  }),
  on(fromActions.FirstLogin, (state, action) => {
    return {
      ...state,
      firstLogin: false
    };
  }),
  on(fromActions.Logout, (state, action) => {
    return {
      ...state,
      userLoading: true
    };
  }),
  on(fromActions.LogoutSuccess, (state, action) => {
    return initialState;
  }),
  on(fromActions.CancelChangeEmail, (state, action) => {
    return {
      ...state,
      cancelChangeEmailSuccess: false,
      changeEmailLoading: true
    };
  }),
  on(fromActions.CancelChangeEmailSuccess, (state, action) => {
    return {
      ...state,
      cancelChangeEmailSuccess: true,
      changeEmailLoading: false
    };
  }),
  on(fromActions.CancelChangeEmailFailure, (state, action) => {
    return {
      ...state,
      cancelChangeEmailSuccess: false,
      changeEmailLoading: false,
      changeEmailError: action.payload.error.errorMessage
    };
  }),
  on(fromActions.CancelChangeEmailErrorsReset, (state, action) => {
    return {
      ...state,
      changeEmailError: initialState.changeEmailError
    };
  }),
  on(fromActions.ConfirmChangeEmail, (state, action) => {
    return {
      ...state,
      changeEmailLoading: true
    };
  }),
  on(fromActions.ConfirmChangeEmailSuccess, (state, action) => {
    return {
      ...state,
      changeEmailLoading: false
    };
  }),
  on(fromActions.ConfirmChangeEmailFailure, (state, action) => {
    return {
      ...state,
      changeEmailLoading: false
    };
  }),
  on(fromActions.DeleteUserAccountSuccess, (state, action) => {
    return initialState;
  }),
  on(fromActions.RegisterButtonReset, (state, action) => {
    return {
      ...state,
      buttonLoading: false,
      emailButtonLoading: false,
      userButtonLoading: false,
      showEmailMessage: false
    };
  }),
  on(fromActions.ResetPasswordInit, (state, action) => {
    return {
      ...state,
      buttonLoading: true
    };
  }),
  on(fromActions.ResetPasswordInitSuccess, (state, action) => {
    return {
      ...state,
      buttonLoading: false
    };
  }),
  on(fromActions.ResetPasswordInitFailure, (state, action) => {
    return {
      ...state,
      buttonLoading: false
    };
  }),
  on(fromActions.ResetPasswordComplete, (state, action) => {
    return {
      ...state,
      buttonLoading: true
    };
  }),
  on(fromActions.ResetPasswordCompleteSuccess, (state, action) => {
    return {
      ...state,
      buttonLoading: false
    };
  }),
  on(fromActions.ResetPasswordCompleteFailure, (state, action) => {
    return {
      ...state,
      buttonLoading: false
    };
  }),
  on(fromActions.ChangeEmail, (state, action) => {
    return {
      ...state,
      showEmailMessage: false,
      emailButtonLoading: true
    };
  }),
  on(fromActions.ChangeEmailSuccess, (state, action) => {
    return {
      ...state,
      showEmailMessage: true,
      emailButtonLoading: false
    };
  }),
  on(fromActions.ChangeEmailFailure, (state, action) => {
    return {
      ...state,
      emailButtonLoading: false
    };
  }),
  on(fromActions.ChangeUsername, (state, action) => {
    return {
      ...state,
      userButtonLoading: true
    };
  }),
  on(fromActions.ChangeUsernameSuccess, (state, action) => {
    return {
      ...state,
      userButtonLoading: false
    };
  }),
  on(fromActions.ChangeUsernameFailure, (state, action) => {
    return {
      ...state,
      userButtonLoading: false
    };
  }),
  on(fromActions.ChangePassword, (state, action) => {
    return {
      ...state,
      buttonLoading: true
    };
  }),
  on(fromActions.ChangePasswordSuccess, (state, action) => {
    return {
      ...state,
      buttonLoading: false
    };
  }),
  on(fromActions.ChangePasswordFailure, (state, action) => {
    return {
      ...state,
      buttonLoading: false
    };
  })
);

export function reducer(
  state: UserState | undefined,
  action: Action
): UserState {
  return userReducer(state, action);
}

export const isLoggedIn = (state: UserState) =>
  state && state.loggedIn ? state.loggedIn : false;

export const isUserLoading = (state: UserState) =>
  state && state.userLoading ? state.userLoading : false;

export const firstLogin = (state: UserState) =>
  state && state.firstLogin ? state.firstLogin : false;

export const buttonLoading = (state: UserState) =>
  state && state.buttonLoading ? state.buttonLoading : false;

export const emailButtonLoading = (state: UserState) =>
  state && state.emailButtonLoading ? state.emailButtonLoading : false;

export const userButtonLoading = (state: UserState) =>
  state && state.userButtonLoading ? state.userButtonLoading : false;

export const cancelChangeEmailSuccess = (state: UserState) =>
  state && state.cancelChangeEmailSuccess
    ? state.cancelChangeEmailSuccess
    : false;

export const changeEmailLoading = (state: UserState) =>
  state && state.changeEmailLoading ? state.changeEmailLoading : false;

export const getChangeEmailError = (state: UserState) =>
  state ? state.changeEmailError : null;

export const getEmail = (state: UserState) => (state ? state.email : null);

export const getIdToken = (state: UserState) => (state ? state.idToken : null);

export const getRefreshToken = (state: UserState) =>
  state ? state.refreshToken : null;

export const getAccessToken = (state: UserState) =>
  state ? state.accessToken : null;

export const getAccountId = (state: UserState) =>
  state ? state.accountId : null;

export const isEmailMessage = (state: UserState) =>
  state && state.showEmailMessage ? state.showEmailMessage : false;
