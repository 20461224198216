<div class="activity-container">
  <div class="activity">
    <div class="activity-header">
      <p class="dashboard-card-subtitle">Latest Interactions</p>
      <h2>Your Activity</h2>
    </div>
    <div *ngFor="let date of dateArray"  class="activity-info">
      <hr>
      <p class="dashboard-card-subtitle date">{{ formatDateLong(date) }}</p>
      <div *ngFor="let activity of allActivities">
        <div *ngIf="dateCheck(date, activity.occurred)"  class="activity-row">
          <img [src]="getIcon(activity.asset)" alt="" class="icon">
          <p class="caption info">{{ activity.summary }}</p>
          <p class="caption">{{ formatDateShort(activity.occurred) }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
