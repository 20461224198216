import { AuthEffects } from './auth.effects';
import { UserDetailsEffects } from './userDetails.effects';
import { LeaderboardEffects } from './leaderboard.effects';
import { AssetEffects } from './assets.effects';

export const effects: any[] = [
  AuthEffects,
  UserDetailsEffects,
  LeaderboardEffects,
  AssetEffects
];
export * from './auth.effects';
export * from './userDetails.effects';
export * from './leaderboard.effects';
export * from './assets.effects';
