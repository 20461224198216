import {
  Component,
  OnInit,
  HostListener,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '../../store';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AssetState } from '../../store/reducers/assets.reducer';
import { UserDetails } from '../../models/userDetails.model';
import { LeaderboardState } from '../../store/reducers/leaderboard.reducer';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  url: string;

  innerWidth: any;
  swiper1Scrollable: boolean;
  swiper2Scrollable: boolean;
  swiper3Scrollable: boolean;

  authData$: Observable<any>;
  authDataSubscription: Subscription;
  authData: any;

  assetData$: Observable<AssetState>;
  assetDataSubscription: Subscription;
  assetData: AssetState;

  userDetails$: Observable<UserDetails>;
  userDetailsSubscription: Subscription;
  userDetails: UserDetails;

  leaderboards$: Observable<LeaderboardState>;
  leaderboardsSubscription: Subscription;
  leaderboards: LeaderboardState;

  loading: boolean;
  errorLoading: boolean;

  achievementArray = [];

  sortedActivities = [];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.url === '/dashboard') {
      this.checkSwipersScrollable();
    }
  }

  constructor(
    private store: Store<fromStore.AppState>,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.id === 1 && event.url === event.urlAfterRedirects) {
          this.store.dispatch(fromStore.GetUserDetails());
          this.store.dispatch(fromStore.GetCrowsLeaderboard());
        }
      });
    this.authData$ = this.store.pipe(select(fromStore.getAuthState));
    this.assetData$ = this.store.pipe(select(fromStore.getAssetState));
    this.userDetails$ = this.store.pipe(select(fromStore.getUserDetailsState));
    this.leaderboards$ = this.store.pipe(select(fromStore.getLeaderboardState));
  }

  ngOnInit(): void {
    this.url = this.router.url;
    if (this.authData$) {
      this.authDataSubscription = this.authData$.subscribe((data) => {
        this.authData = data;
      });
    }
    if (this.assetData$) {
      this.assetDataSubscription = this.assetData$.subscribe((data) => {
        this.assetData = data;
        if (data.vipAssets) {
          this.achievementArray = this.getAchievementArray();
        }
        if (this.userDetails && this.leaderboards) {
          this.loading =
            this.assetData.loading ||
            this.userDetails.userDetailsLoading ||
            this.leaderboards.leaderBoardLoading;
        } else {
          this.loading = false;
        }
      });
    }
    if (this.userDetails$) {
      this.userDetailsSubscription = this.userDetails$.subscribe((data) => {
        this.userDetails = data;
        if (data.activities) {
          data.activities.forEach((activity) => {
            if (
              !this.sortedActivities.find(
                (e) => activity.identifier === e.identifier
              )
            ) {
              this.sortedActivities.push(activity);
            }
          });
          this.sortedActivities.sort(this.sortActivities);
        }
        if (this.assetData && this.leaderboards) {
          this.loading =
            this.assetData.loading ||
            this.userDetails.userDetailsLoading ||
            this.leaderboards.leaderBoardLoading;
        } else {
          if (
            !this.userDetails.userInfo &&
            !this.userDetails.userDetailsLoading
          ) {
            this.errorLoading = true;
            this.store.dispatch(fromStore.Logout({ isDashboardError: true }));
          } else {
            this.loading = false;
          }
        }
      });
    }
    if (this.leaderboards$) {
      this.leaderboardsSubscription = this.leaderboards$.subscribe((data) => {
        this.leaderboards = data;
        if (this.assetData && this.userDetails) {
          this.loading =
            this.assetData.loading ||
            this.userDetails.userDetailsLoading ||
            this.leaderboards.leaderBoardLoading;
        } else {
          this.loading = false;
        }
      });
    }
  }

  ngAfterViewInit() {
    if (this.url === '/dashboard') {
      this.checkSwipersScrollable();
    }
  }

  getAchievementArray(): Array<any> {
    const achievementArray = [];
    this.assetData.vipAssets.forEach((asset) => {
      asset.achievements.forEach((achievement) => {
        achievementArray.push({
          ...achievement,
          backgroundImageURL: asset.backgroundImageURL,
          iconImageURL: asset.iconImageURL
        });
      });
    });
    return achievementArray;
  }

  getObjectiveArray(): Array<any> {
    const achievementArray = this.getAchievementArray();
    const objectiveArray = [];
    achievementArray.forEach((achievement) => {
      achievement.objectives.forEach((objective) => {
        objectiveArray.push(objective);
      });
    });
    return objectiveArray;
  }

  checkSwipersScrollable() {
    const swiper1 = document.querySelector('.swiper1');
    const swiper2 = document.querySelector('.swiper2');
    const swiper3 = document.querySelector('.swiper3');
    if (swiper1) {
      this.swiper1Scrollable = swiper1.scrollWidth > swiper1.clientWidth;
    }
    if (swiper2) {
      this.swiper2Scrollable = swiper2.scrollWidth > swiper2.clientWidth;
    }
    if (swiper3) {
      this.swiper3Scrollable = swiper3.scrollWidth > swiper3.clientWidth;
    }
    this.cd.detectChanges();
  }

  getCurrentComponent(url: string): string {
    switch (url) {
      case '/activity':
        return 'activity';
      case '/achievements':
        return 'achievements';
      case '/leaderboards': {
        return 'leaderboards';
      }
      default: {
        if (url.startsWith('/activity-detail')) {
          return 'activity-detail';
        }
        if (url.startsWith('/achievement-detail')) {
          return 'achievement-detail';
        }
        if (url.startsWith('/objective-detail')) {
          return 'objective-detail';
        }
        if (url.startsWith('/leaderboard-detail')) {
          return 'leaderboard-detail';
        }
      }
    }
  }

  slideLeft(swiperId): void {
    const swiper = document.querySelector('.swiper' + swiperId);
    swiper.scrollBy(-100, 0);
  }

  slideRight(swiperId): void {
    const swiper = document.querySelector('.swiper' + swiperId);
    swiper.scrollBy(100, 0);
  }

  toLeaderboards() {
    if (this.leaderboards.crowsLeaderBoard) {
      this.router.navigateByUrl('/leaderboards');
    }
  }

  sortActivities(a: any, b: any) {
    const activityA = a.occurred;
    const activityB = b.occurred;

    let comparison = 0;
    if (activityA > activityB) {
      comparison = -1;
    } else if (activityA < activityB) {
      comparison = 1;
    }
    return comparison;
  }
}
