import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginResponse, SettingsResponse } from '../models/auth.models';
import { DashboardData } from '../models/dashboard.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
  constructor(private http: HttpClient) {}

  /**
   * Get user information for dashboard.
   */
  getUserDetails(token: string): Observable<DashboardData> {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http.get<DashboardData>('/vip/user/VIP/getUserDetails', {
      headers
    });
  }
}
