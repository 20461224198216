import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromUser from '../reducers/user.reducer';

export const getCadetState = createSelector(
  fromFeature.getUserState,
  (state: fromFeature.AppState) => (state ? state.user : {})
);

export const isLoggedIn = createSelector(
  fromFeature.getAuthState,
  fromUser.isLoggedIn
);

export const isUserLoading = createSelector(
  fromFeature.getAuthState,
  fromUser.isUserLoading
);

export const firstLogin = createSelector(
  fromFeature.getAuthState,
  fromUser.firstLogin
);

export const getIdToken = createSelector(
  fromFeature.getAuthState,
  fromUser.getIdToken
);

export const getAccessToken = createSelector(
  fromFeature.getAuthState,
  fromUser.getAccessToken
);

export const getRefreshToken = createSelector(
  fromFeature.getAuthState,
  fromUser.getRefreshToken
);

export const getEmail = createSelector(
  fromFeature.getAuthState,
  fromUser.getEmail
);

export const getAccountId = createSelector(
  fromFeature.getAuthState,
  fromUser.getAccountId
);

export const isButtonLoading = createSelector(
  fromFeature.getAuthState,
  fromUser.buttonLoading
);

export const isEmailButtonLoading = createSelector(
  fromFeature.getAuthState,
  fromUser.emailButtonLoading
);

export const isUserButtonLoading = createSelector(
  fromFeature.getAuthState,
  fromUser.userButtonLoading
);

// export const getSettingsMessages = createSelector(
//   fromFeature.getAuthState,
//   fromUser.getSettingsMessages
// );

export const cancelChangeEmailSuccess = createSelector(
  fromFeature.getAuthState,
  fromUser.cancelChangeEmailSuccess
);

export const changeEmailLoading = createSelector(
  fromFeature.getAuthState,
  fromUser.changeEmailLoading
);

export const isEmailMessage = createSelector(
  fromFeature.getAuthState,
  fromUser.isEmailMessage
);

export const getChangeEmailError = createSelector(
  fromFeature.getAuthState,
  fromUser.getChangeEmailError
);
