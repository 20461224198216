import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Message } from '../../store/models/message.model';

import * as notificationsActions from '../actions';
import * as authActions from '../../../store/actions/auth.actions';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class NotificationEffects {
  dismissOnRouteChange: boolean;
  dontVerify: boolean;

  emailChanged: boolean;
  passwordChanged: boolean;
  usernameChanged: boolean;

  emailSuccess: boolean;
  passwordSuccess: boolean;
  usernameSuccess: boolean;

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (this.dismissOnRouteChange) {
          this.dontVerify = true;
          this.snackBar.dismiss();
          this.dismissOnRouteChange = false;
          this.dontVerify = false;
        }
      });
  }

  loginFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.LoginFailure)).pipe(
        map((action) => {
          const error = action.payload.error.errorType;
          let errorMessage = '';
          let resetPassword = false;
          switch (error) {
            case 'UserNotConfirmedException' || '': {
              errorMessage =
                'You have an account but it is not confirmed. Please complete the registration process before signing in.';
              break;
            }
            case 'PasswordResetRequiredException': {
              errorMessage = `This account's password has expired. Please use Forgot My Password to reset your password.`;
              resetPassword = true;
              this.dismissOnRouteChange = true;
              break;
            }
            case 'UserNotFoundException': {
              errorMessage = `You've entered an invalid email address or password. Please try again.`;
              break;
            }
            case 'TooManyRequestsException': {
              errorMessage = `You've exceeded the number of sign in attempts. Please wait and try again later.`;
              break;
            }
            case 'InvalidPasswordException': {
              errorMessage = `You've entered an invalid email address or password. Please try again.`;
              break;
            }
            default: {
              errorMessage =
                'We encountered an error trying to sign you in. Please try again later.';
            }
          }
          const snackmessage = {
            text: errorMessage,
            type: 'failure'
          };
          if (resetPassword) {
            this.openTillClosed(snackmessage, 'Reset Password');
            this.snackBar._openedSnackBarRef.afterDismissed().subscribe(() => {
              if (!this.dontVerify) {
                this.router.navigateByUrl('/forgot-password');
              }
            });
          } else {
            this.open(snackmessage);
          }

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  logoutFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.LogoutFailure)).pipe(
        map((action) => {
          const message = {
            text: 'We encountered an error trying to sign you out. Please try again later.',
            type: 'failure'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  signupSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.SignupSuccess)).pipe(
        map(() => {
          const message = {
            text: 'Your account was successfully created.',
            type: 'success'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  signupFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.SignupFailure)).pipe(
        map((action) => {
          const error = action.payload.error.errorType;
          let errorMessage = '';
          switch (error) {
            case 'MissingInputDataException' || '': {
              errorMessage = 'All required fields must be filled out.';
              break;
            }
            case 'AccountAlreadyVerifiedException': {
              errorMessage =
                'Your account is already confirmed. Please sign in to continue.';
              break;
            }
            case 'TooManyRequestsException': {
              errorMessage = `You've exceeded the number of confirmation attempts. Please wait and try again later.`;
              break;
            }
            case 'VerificationCodeExpiredException': {
              errorMessage =
                'Your confirmation code is expired. Please go back to the previous page and request a email address change.';
              break;
            }
            case 'VerificationCodeInvalidException': {
              errorMessage =
                'You entered an invalid confirmation code. Please check your confirmation code and try again.';
              break;
            }
            default: {
              errorMessage =
                'We encountered an error trying to confirm your account. Please try again later.';
            }
          }
          const snackmessage = {
            text: errorMessage,
            type: 'failure'
          };

          this.open(snackmessage);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  resetPasswordInitSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ResetPasswordInitSuccess)).pipe(
        map(() => {
          const message = {
            text: 'A confirmation code has been sent to your email address',
            type: 'success'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  resetPasswordInitFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ResetPasswordInitFailure)).pipe(
        map((action) => {
          const error = action.payload.error.errorType;
          let errorMessage = '';
          switch (error) {
            case 'UserNotFoundException': {
              errorMessage =
                'There is no account associated with you email address. If the email address you entered is correct, please create an account to continue.';
              break;
            }
            default: {
              errorMessage =
                'We encountered a problem resetting your password. Please try again later.';
            }
          }
          const message = {
            text: errorMessage,
            type: 'failure'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  resetPasswordCompleteFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ResetPasswordCompleteFailure)).pipe(
        map((action) => {
          const error = action.payload.error.errorType;
          let errorMessage = '';
          switch (error) {
            case 'VerificationCodeInvalidException': {
              errorMessage =
                'Your confirmation code is invalid. Please request a code to continue.';
              break;
            }
            case 'VerificationCodeExpiredException': {
              errorMessage =
                'Your confirmation code has expired. Please go back to the previous page and request a confirmation code.';
              break;
            }
            case 'LimitExceededException': {
              errorMessage = `You've exceeded the number of reset attempts. Please wait and try again later.`;
              break;
            }
            default: {
              errorMessage = `We've encountered a problem resetting your password. Please try again later.`;
            }
          }
          const message = {
            text: errorMessage,
            type: 'failure'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  resetPasswordCompleteSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ResetPasswordCompleteSuccess)).pipe(
        map(() => {
          const message = {
            text: 'Password successfully changed',
            type: 'success'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  verifyEmailFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.VerifyEmailFailure)).pipe(
        map((action) => {
          const error = action.payload.error.errorType;
          let errorMessage = '';
          switch (error) {
            case 'AccountExistsException': {
              errorMessage =
                'This email address already has an account. Please sign in to continue.';
              break;
            }
            case 'AccountExistsNotVerifiedException': {
              errorMessage =
                'This email address already has an account. Please sign in to continue.';
              break;
            }
            default: {
              errorMessage =
                'We encountered an error trying to register your account. Please try again later.';
            }
          }
          const message = {
            text: errorMessage,
            type: 'failure'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  exportUserDataSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ExportUserDataSuccess)).pipe(
        map(() => {
          const message = {
            text: 'Your user data was sent to your email address.',
            type: 'success'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  exportUserDataFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ExportUserDataFailure)).pipe(
        map((action) => {
          const message = {
            text: 'We encountered a problem exporting your data. Please try again later.',
            type: 'failure'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  deleteAccountSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.DeleteUserAccountSuccess)).pipe(
        map(() => {
          const message = {
            text: 'Your account has been deleted.',
            type: 'success'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  deleteAccountFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.DeleteUserAccountFailure)).pipe(
        map((action) => {
          const message = {
            text: 'We encountered a problem deleting your account. Please try again later.',
            type: 'failure'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  resendConfirmationCodeSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$
        .pipe(ofType(authActions.ResendConfirmationCodeSuccess))
        .pipe(
          map(() => {
            const message = {
              text: 'A confirmation code was sent to your email address.',
              type: 'success'
            };

            this.open(message);

            notificationsActions.NotifySuccess();
          })
        ),
    { dispatch: false }
  );

  resendConfirmationCodeFailure$: Observable<any> = createEffect(
    () =>
      this.actions$
        .pipe(ofType(authActions.ResendConfirmationCodeFailure))
        .pipe(
          map((action) => {
            const message = {
              text: 'We encountered a problem sending your confirmation code. Please try again later.',
              type: 'failure'
            };

            this.open(message);

            notificationsActions.NotifySuccess();
          })
        ),
    { dispatch: false }
  );

  initChangeEmailFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ChangeEmailFailure)).pipe(
        map(() => {
          const message = {
            text: 'We encountered a problem updating your email.',
            type: 'failure'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  changeUsernameFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ChangeUsernameFailure)).pipe(
        map(() => {
          const message = {
            text: 'We encountered a problem updating your username.',
            type: 'failure'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  changeUsernameSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ChangeUsernameSuccess)).pipe(
        map(() => {
          const message = {
            text: 'Successfully updated your VIP name.',
            type: 'success'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  changePasswordFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ChangePasswordFailure)).pipe(
        map((action) => {
          const error = action.payload.error.errorType;
          let errorMessage = '';
          switch (error) {
            case 'InvalidCurrentPasswordException': {
              errorMessage =
                'Your current password was incorrect. Please enter your current password or click "Forgot My Password"';
              break;
            }
            default: {
              errorMessage = 'We encountered a problem updating your password.';
            }
          }
          const message = {
            text: errorMessage,
            type: 'failure'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  changePasswordSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ChangePasswordSuccess)).pipe(
        map(() => {
          const message = {
            text: 'Successfully updated your password.',
            type: 'success'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  confirmChangeEmailFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ConfirmChangeEmailFailure)).pipe(
        map((action) => {
          const error = action.payload.error.errorType;
          let errorMessage = '';
          switch (error) {
            case 'VerificationCodeInvalidException': {
              errorMessage =
                'Your confirmation code is invalid. Please request a code to continue.';
              break;
            }
            case 'VerificationCodeExpiredException': {
              errorMessage =
                'Your confirmation code has expired. Please request a code to continue.';
              break;
            }
            default: {
              errorMessage =
                'We encountered a problem updating your email. This request may have already been canceled. Please go back to the previous page and try to change your email address again.';
            }
          }
          const message = {
            text: errorMessage,
            type: 'failure'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  confirmChangeEmailSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.ConfirmChangeEmailSuccess)).pipe(
        map(() => {
          const message = {
            text: 'Your email was successfully changed.',
            type: 'success'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  delayedSignupSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.DelayedSignupSuccess)).pipe(
        map(() => {
          const message = {
            text: 'Your account was successfully created.',
            type: 'success'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  delayedSignupFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(ofType(authActions.DelayedSignupFailure)).pipe(
        map((action) => {
          const message = {
            text: 'Something went wrong, please try again later.',
            type: 'failure'
          };

          this.open(message);

          notificationsActions.NotifySuccess();
        })
      ),
    { dispatch: false }
  );

  open(message: Message, duration = 5000): void {
    this.snackBar.open(message.text, 'Dismiss', {
      duration,
      panelClass: message.type
    });
  }

  openTillClosed(message: Message, buttonText: string): void {
    this.snackBar.open(message.text, buttonText, {
      panelClass: message.type
    });
  }
}
