import { createAction } from '@ngrx/store';
export const GetUserDetails = createAction(
  '[USER] Get User Details',
  (payload = null) => ({ payload })
);
export const GetUserDetailsSuccess = createAction(
  '[USER] Get User Details Success',
  (payload = null) => ({ payload })
);
export const GetUserDetailsFailure = createAction(
  '[USER] Get User Details Failure',
  (payload = null) => ({ payload })
);
